import {
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { FC } from 'react';
import { SelectChangeEvent } from '@mui/material/Select';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface AddDashboardItemsProps {
  widgetsDropdown: string;
  handleDropdownChange: (event: SelectChangeEvent) => void;
  addNewWidget: (
    x?: number,
    y?: number,
    w?: number,
    h?: number,
    id?: string,
    minH?: number,
    minW?: number
  ) => void;
}

const AddDashboardWidgets: FC<AddDashboardItemsProps> = ({
  widgetsDropdown,
  handleDropdownChange,
  addNewWidget,
}) => {
  return (
    <Box display="flex" flexDirection="row-reverse" mb={1}>
      <FormControl style={{ width: '120px', marginLeft: '16px' }}>
        <InputLabel id="dropdown-label">
          <Box display="flex" alignItems="center">
            <AddCircleOutlineIcon fontSize="small" /> <Box ml={1}>Add</Box>
          </Box>
        </InputLabel>
        <Select
          labelId="dropdown-label"
          id="dropdown"
          value={widgetsDropdown}
          label={
            <>
              <AddCircleOutlineIcon fontSize="small" />
              Add
              <Box ml={1}>Add</Box>
            </>
          }
          onChange={handleDropdownChange}
        >
          <MenuItem value={'bar'}>Bar Chart</MenuItem>
          <MenuItem value={'pie'}>Pie Chart</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        startIcon={<AddCircleOutlineIcon fontSize="small" />}
        onClick={() => addNewWidget()}
      >
        1
      </Button>
    </Box>
  );
};

export default AddDashboardWidgets;
