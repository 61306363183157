import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { authApi } from '../../../api/authApi';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import useAuthorization from '../../../hooks/useAuthorization';

const LoginJWT: FC = (props) => {
  const client = useApolloClient();
  const mounted = useMounted();
  const { login } = useAuth();
  const { fetchPermissions } = useAuthorization();
  const { i18n } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: 'arch@qs.de',
        password: 'secret',
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        password: Yup.string().max(255).required('Password is required'),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          await login(values.email, values.password);

          let accessToken = sessionStorage.getItem('accessToken');
          const response = await authApi.locale(accessToken);
          i18n.addResourceBundle('de', 'translation', response.de);

          await client.clearStore();
          await fetchPermissions();

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (error) {
          if (mounted.current) {
            setStatus({ success: false });
            let errorMessage = 'Error';
            if (error instanceof Error) {
              errorMessage = error.message;
              setErrors({ submit: errorMessage });
            }
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit} {...props}>
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email Address"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Log In
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
