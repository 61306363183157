import { Typography } from '@mui/material';

interface TextInputProps {
  value?: string | null;
  multiline?: boolean;
}

const TextDisplay = ({ value }: TextInputProps) => {
  // TODO: handle multiline
  return <Typography variant="body2">{value}</Typography>;
};

export default TextDisplay;
