import { useField } from 'formik';
import { NumberFormatValues } from 'react-number-format';
import FormField from '../form/FormField';
import { FormGridProps } from '../form/FormGridProps';
import IntegerInput from './IntegerInput';

interface FormIntegerInputProps {
  name: string;
  label?: string;
  showThousandSeperator?: boolean;
}

const FormIntegerInput = ({
  showThousandSeperator = false,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: FormIntegerInputProps & FormGridProps) => {
  const [field, meta, form] = useField<number | null>(props.name);
  const handleChange = (value: NumberFormatValues) => {
    form.setValue(value.floatValue || null);
  };
  const handleBlur = () => {
    form.setTouched(true);
  };
  return (
    <FormField xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <IntegerInput
        label={props.label}
        value={field.value}
        handleChange={handleChange}
        handleBlur={handleBlur}
        showThousandSeperator={showThousandSeperator}
        error={meta.touched && meta.error ? meta.error : undefined}
        limitRerender
      />
    </FormField>
  );
};

export default FormIntegerInput;
