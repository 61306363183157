import gql from 'graphql-tag';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import Form from '../../common/form/Form';
import FormSection from '../../common/form/FormSection';
import FormCurrencyInput from '../../common/inputs/FormCurrencyInput';
import FormDateInput from '../../common/inputs/FormDateInput';
import FormIntegerInput from '../../common/inputs/FormIntegerInput';
import FormTextInput from '../../common/inputs/FormTextInput';
import FormDropDownInput from '../../common/inputs/FromDropDownInput';
import { Order } from '../../schema/Order';
import { UpdateOrder, UpdateOrderVariables } from '../../schema/UpdateOrder';

const UPDATE_ORDER = gql`
  mutation UpdateOrder($auftragData: UpdateAuftragInput!) {
    demo {
      updateAuftrag(auftragData: $auftragData) {
        id
        projektId
        auftragsnummer
        auftragnehmer
        leistungsbez
        art
        auftragssumme
        auftragsdatum
        leistungsbeschreibung
        angebotsdatum
        gewerk
        strasse
        plz
        ort
        email
        telefon
      }
    }
  }
`;

interface BasicDataFormProps {
  children?: ReactNode;
  order: Order;
  refetch: () => void;
}

const BasicDataForm: FC<BasicDataFormProps> = ({ order, refetch }) => {
  const { t } = useTranslation();

  const makeVars = (values: Order) => {
    const { __typename, ...updatedOrder } = values;
    return {
      auftragData: {
        ...updatedOrder,
        auftragnehmer: values.auftragnehmer ?? '',
        id: values.id ?? '',
      },
    };
  };

  const validationSchema = Yup.object().shape({
    auftragssumme: Yup.number()
      .typeError('Ungültie Ganzzahl')
      .required('Auftragssummer ist ein Pflichtfeld')
      .nullable(true),
    angebotsdatum: Yup.date()
      .typeError('Ungültiges Datum')
      .required('Angebotsdatum ist ein Pflichtfeld!')
      .nullable(true),
    auftragsdatum: Yup.date()
      .typeError('Ungültiges Datum')
      .when('angebotsdatum', (date, schema) => {
        return date && !isNaN(date)
          ? schema.max(
              date,
              'Das Auftragsdatum muss vor dem Angebotsdatum liegen.'
            )
          : schema;
      })
      .required('Auftragsdatum ist ein Pflichtfeld')
      .nullable(true),
  });

  if (order === undefined) return <></>;

  return (
    <Form<Order, UpdateOrder, UpdateOrderVariables>
      initialValues={order}
      mutation={UPDATE_ORDER}
      makeVars={makeVars}
      refetch={refetch}
      validationSchema={validationSchema}
    >
      <FormDropDownInput
        items={[
          { key: 'HAUPT', text: 'Hauptauftrag' },
          { key: 'NACH', text: 'Nachtrag' },
          { key: 'NICHT', text: 'Nicht beauftragte Kostennänderung' },
        ]}
        name="art"
        label="Auftragsart"
      />

      <FormSection xs={12} xl={6}>
        <FormTextInput name="leistungsbez" label="Leistungsbezeichnung" />
        <FormTextInput name="gewerk" label={t('gewerk')} />
        <FormIntegerInput name="auftragsnummer" label="Auftragsnummer" xl={6} />
        <FormCurrencyInput name="auftragssumme" label="Auftragssumme" xl={6} />
        <FormDateInput name="auftragsdatum" label="Auftragsdatum" xl={6} />
        <FormDateInput name="angebotsdatum" label="Angebotsdatum" xl={6} />
      </FormSection>

      <FormSection xl={6}>
        <FormTextInput name="auftragnehmer" label="Auftragnehmer" />
        <FormTextInput name="strasse" label="Straße" />
        <FormTextInput name="plz" label="PLZ" xl={3} />
        <FormTextInput name="ort" label="Ort" xl={9} />
        <FormTextInput name="telefon" label="Telefon" />
      </FormSection>

      <FormSection>
        <FormTextInput
          name="leistungsbeschreibung"
          label="Leistungsbeschreibung"
          multiline={true}
        />
        <FormTextInput name="email" label="E-Mail" />
      </FormSection>
    </Form>
  );
};

export default BasicDataForm;
