import { FC } from 'react';
import ProjectListTable from '../projects/ProjectListTable';
import ExampleBarChartWidget from './ExampleBarChartWidget';
import ExamplePiChartWidget from './ExamplePieChartWidget';

interface WidgetProps {
  id: string;
  height: number;
}

export interface CommonWidgetProps {
  height: number;
}

enum WidgetID {
  ProjectList = '1',
  BarChart = 'bar',
  PieChart = 'pie',
}

const Widget: FC<WidgetProps> = ({ id, height }) => {
  switch (id) {
    case WidgetID.ProjectList:
      return <ProjectListTable tableHeight={height} />;
    case WidgetID.BarChart:
      return <ExampleBarChartWidget height={height} />;
    case WidgetID.PieChart:
      return <ExamplePiChartWidget height={height} />;
    default:
      return <>{id}</>;
  }
};

export default Widget;
