import { FC } from 'react';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { BarChartDataType } from '../../domain/widgets/ExampleBarChartWidget';

interface BarChartProps {
  height: number;
  data: BarChartDataType;
}

const BarChart: FC<BarChartProps> = ({ height, data }) => {
  const getHeight = () => {
    return height * 100 - 52;
  };

  let formattedData = {
    options: {
      xaxis: {
        categories: data.categories,
      },
    },
    series: [{ data: data.data }],
  };

  const [state] = useState(formattedData);

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="bar"
      height={getHeight()}
    />
  );
};

export default BarChart;
