import XIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Form, { FormProps } from './Form';

type FormModalProps<TInput, TVariables> = {
  open: boolean;
  onClose?: () => void;
  title: string;
} & FormProps<TInput, TVariables>;

function FormModal<TInput, TData, TVariables>({
  children,
  open,
  onClose,
  title,
  ...props
}: React.PropsWithChildren<FormModalProps<TInput, TVariables>>) {
  return (
    <Dialog fullWidth maxWidth="md" open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6" color="textPrimary">
          {title}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <XIcon fontSize="small" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Form<TInput, TData, TVariables>
        {...props}
        wrapBody={({ children }) => <>{children}</>}
        wrapForm={({ children }) => (
          <DialogContent dividers>{children}</DialogContent>
        )}
        wrapAction={({ children }) => <DialogActions>{children}</DialogActions>}
        onClose={onClose}
      >
        {children}
      </Form>
    </Dialog>
  );
}

export default FormModal;
