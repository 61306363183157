import {
  Chip,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BooleanInput from '../inputs/BooleanInput';
import { GroupField } from './PivotTable';
import SearchIcon from '@mui/icons-material/Search';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const getItemStyleSelected = (isDragging: boolean, draggableStyle: any) => ({
  marginBottom: 4,
  display: 'flex',
  ...draggableStyle,
});

const getListStyleVertical = (isDraggingOver: boolean) => ({
  overflow: 'auto',
});

export interface PivotControlState {
  selected: GroupField<any>[];
  unselected: GroupField<any>[];
  showData: boolean;
  showCount: boolean;
  showRoot: boolean;
  searchValue: string | null;
  searchResultCount: number | null;
  selectedIndex: number | null;
}

interface PivotControlProps {
  state: PivotControlState;
  onChange: React.Dispatch<React.SetStateAction<PivotControlState>>;
}

const PivotControl = ({ state, onChange }: PivotControlProps) => {
  const reorder = (
    list: GroupField<any>[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'droppableSelected') {
        const items = reorder(state.selected, source.index, destination.index);
        onChange((oldState) => ({ ...oldState, selected: items }));
      } else if (source.droppableId === 'droppableUnselected') {
        const items = reorder(
          state.unselected,
          source.index,
          destination.index
        );
        onChange((oldState) => ({ ...oldState, unselected: items }));
      }
    } else {
      if (source.droppableId === 'droppableSelected') {
        let newSelected = state.selected;
        const [removed] = newSelected.splice(source.index, 1);
        let newUnselected = state.unselected;
        newUnselected.splice(destination.index, 0, removed);
        onChange((oldState) => ({
          ...oldState,
          selected: newSelected,
          unselected: newUnselected,
        }));
      } else if (source.droppableId === 'droppableUnselected') {
        let newUnselected = state.unselected;
        const [removed] = newUnselected.splice(source.index, 1);
        let newSelected = state.selected;
        newSelected.splice(destination.index, 0, removed);
        onChange((oldState) => ({
          ...oldState,
          selected: newSelected,
          unselected: newUnselected,
        }));
      }
    }
  };

  const handleDelete = (item: GroupField<any>, index: number) => {
    const newSelected = state.selected;
    newSelected.splice(index, 1);
    const newUnselected = state.unselected.concat(item);
    onChange((oldState) => ({
      ...oldState,
      selected: newSelected,
      unselected: newUnselected,
    }));
  };

  const handleAdd = (item: GroupField<any>, index: number) => {
    const newSelected = state.selected;
    let newUnselected = state.unselected;
    const [removed] = newUnselected.splice(index, 1);
    newSelected.push(removed);
    onChange((oldState) => ({
      ...oldState,
      selected: newSelected,
      unselected: newUnselected,
    }));
  };

  return (
    <Box>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box>
          <Box sx={{ paddingX: 1, paddingTop: 1 }}>
            <Typography variant="subtitle2">Gliederung:</Typography>
          </Box>
          <Box sx={{ padding: 1 }}>
            <Droppable droppableId="droppableSelected" direction="vertical">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getListStyleVertical(snapshot.isDraggingOver)}
                >
                  {state.selected.map((item, index) => (
                    <Draggable
                      key={item.name}
                      draggableId={item.name}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...getItemStyleSelected(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            ),
                            marginLeft: index * 15,
                          }}
                        >
                          <KeyboardArrowDownIcon />
                          <Chip
                            label={item.name}
                            onDelete={() => handleDelete(item, index)}
                            color={item.color}
                            size="small"
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {state.selected.length === 0 && (
                    <Typography variant="subtitle1">
                      Kein Gliederungselement ausgewählt.
                    </Typography>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </Box>
          {state.unselected.length > 0 && (
            <>
              <Divider />
              <Box sx={{ paddingX: 1, paddingTop: 1 }}>
                <Typography variant="subtitle2">
                  Weitere Gliederungselemente:
                </Typography>
              </Box>
              <Box sx={{ padding: 1 }}>
                <Droppable
                  droppableId="droppableUnselected"
                  direction="vertical"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      style={getListStyleVertical(snapshot.isDraggingOver)}
                    >
                      {state.unselected.map((item, index) => (
                        <Draggable
                          key={item.name}
                          draggableId={item.name}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...getItemStyleSelected(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                ),
                              }}
                            >
                              <Chip
                                label={item.name}
                                color="default"
                                onClick={() => handleAdd(item, index)}
                                clickable
                                size="small"
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Box>
            </>
          )}
        </Box>
      </DragDropContext>
      <Divider />
      <Box sx={{ padding: 1 }}>
        <Box marginBottom={1}>
          <Typography variant="subtitle2">Suche:</Typography>
        </Box>

        <TextField
          fullWidth
          value={state.searchValue || ''}
          onChange={(e) =>
            onChange((old) => {
              return {
                ...old,
                searchValue: e.target.value,
                selectedIndex: 0,
              };
            })
          }
          sx={{ marginBottom: 0, backgroundColor: 'white' }}
          InputProps={{
            sx: { height: 36, padding: 0 },

            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        {state.searchValue && state.showData && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <IconButton
              sx={{ padding: 0 }}
              onClick={() =>
                onChange((old) => ({
                  ...old,
                  selectedIndex: !old.selectedIndex ? 0 : old.selectedIndex - 1,
                }))
              }
            >
              <ChevronLeftIcon fontSize="large" />
            </IconButton>
            <Typography variant="subtitle1">
              {state.selectedIndex! + 1}/{state.searchResultCount}
            </Typography>
            <IconButton
              sx={{ padding: 0 }}
              onClick={() =>
                onChange((old) => ({
                  ...old,
                  selectedIndex:
                    old.selectedIndex === null ? 0 : old.selectedIndex + 1,
                }))
              }
            >
              <ChevronRightIcon fontSize="large" />
            </IconButton>
          </Box>
        )}
      </Box>
      <Divider />
      <Box sx={{ padding: 1 }}>
        <Box>
          <Typography variant="subtitle2">Optionen:</Typography>
        </Box>
        <Box>
          <BooleanInput
            value={state.showData}
            handleChange={(value) =>
              onChange((oldState) => ({ ...oldState, showData: value }))
            }
            label={'Datensätze'}
            toggle
          />
        </Box>
        <Box>
          <BooleanInput
            value={state.showCount}
            handleChange={(value) =>
              onChange((oldState) => ({ ...oldState, showCount: value }))
            }
            label={'Anzahl Elemente'}
            toggle
          />
        </Box>
        <Box>
          <BooleanInput
            value={state.showRoot}
            handleChange={(value) =>
              onChange((oldState) => ({ ...oldState, showRoot: value }))
            }
            label={'Root Element'}
            toggle
          />
        </Box>
      </Box>
    </Box>
  );
};
export default PivotControl;
