import {
  Box,
  Divider,
  Chip,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { parseISO } from 'date-fns';
import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import useSessionState from '../../../hooks/useSessionState';
import FilterClearButton from './FilterClearButton';
import FilterDialog from './FilterDialog';
import FilterMenuButton from './FilterMenuButton';
import FilterSearchInput from './SearchFilter';
import SortIcon from '@mui/icons-material/Sort';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
type FilterFunctionType = 'EQUAL' | 'BETWEEN' | 'INCLUDES' | 'BETWEEN_DATES';

const filterFunctions = {
  EQUAL: (value: any, filterValue: string | number) => {
    return value === filterValue;
  },
  BETWEEN: (value: any, filterValues: { min?: number; max?: number }) => {
    if (filterValues.min && filterValues.max) {
      return value >= filterValues.min && value <= filterValues.max;
    }
    if (
      filterValues.min &&
      (filterValues.max === undefined || filterValues.max === null)
    ) {
      return value >= filterValues.min;
    }
    if (
      filterValues.max &&
      (filterValues.min === undefined || filterValues.min === null)
    ) {
      return value <= filterValues.max;
    }
  },
  BETWEEN_DATES: (value: any, filterValues: { min?: string; max?: string }) => {
    const timestampValue = value ? parseISO(value).getTime() : 0;
    if (filterValues.min && filterValues.max) {
      const timestampMin = parseISO(filterValues.min).getTime();
      const timestampMax = parseISO(filterValues.max).getTime();
      return timestampValue >= timestampMin && timestampValue <= timestampMax;
    }
    if (
      filterValues.min &&
      (filterValues.max === undefined || filterValues.max === null)
    ) {
      const timestampMin = parseISO(filterValues.min).getTime();
      return timestampValue >= timestampMin;
    }
    if (
      filterValues.max &&
      (filterValues.min === undefined || filterValues.min === null)
    ) {
      const timestampMax = parseISO(filterValues.max).getTime();
      return timestampValue <= timestampMax;
    }
    return !!value;
  },
  INCLUDES: (value: any, filterValues: (string | number)[]) => {
    return filterValues.includes(value);
  },
};

const defaultComparer = (prop: string) => {
  return (a: any, b: any) => {
    if (a[prop] && b[prop]) {
      if (a[prop] > b[prop]) {
        return -1;
      }
      if (b > a) {
        return 1;
      }
    } else if (a[prop]) {
      return -1;
    } else if (b[prop]) return 1;
    return 0;
  };
};

export interface SortByItem<T> {
  key: keyof T & string;
  title: string;
  compareTo?: (a: T, b: T) => -1 | 0 | 1;
}

interface FilterBarProps<T> {
  data: T[];
  children?: (data: T[]) => JSX.Element;
  sortBy?: SortByItem<T>[];
  filters?: ColumnFilter[];
  searchFields?: (keyof T & string)[];
  customControl?: (context: IFilterBarContext) => JSX.Element;
  id: string;
}

export interface ColumnFilter {
  name: string;
  heading: string;
  filterComponent: (props: FilterInputProps) => JSX.Element;
  id?: string;
}
export interface ColumnFilterWithKey extends ColumnFilter {
  id: string;
}

export interface FilterInputProps {
  propertyName: string;
  heading: string;
  id: string;
  autoFocus: boolean;
}

export interface Filter {
  propertyName: string;
  filterFunction: FilterFunctionType;
  state: any;
  filterText: string;
  id: string;
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

interface IFilterBarContext {
  filterState: Record<string, Filter>;
  appliedFilterState: Record<string, Filter>;
  setFilter: (filter: Filter) => void;
  setFilterState: React.Dispatch<React.SetStateAction<Record<string, Filter>>>;
  deleteFilter: (id: string) => void;
  applyFilter: () => void;
  data: any[];
  reset: () => void;
  clear: () => void;
  searchValue: string | null;
  setSearchValue: (value: string | null) => void;
  currentFilterResult: any[];
}

export const FilterBarContext = React.createContext<IFilterBarContext>({
  filterState: {},
  appliedFilterState: {},
  setFilter: () => {},
  setFilterState: () => {},
  deleteFilter: () => {},
  applyFilter: () => {},
  data: [],
  reset: () => {},
  clear: () => {},
  searchValue: null,
  setSearchValue: () => {},
  currentFilterResult: [],
});

function useFilterBarContext() {
  const context = useContext(FilterBarContext);
  if (!context) {
    throw new Error(
      `Filter components cannot be rendered outside the FilterBar component`
    );
  }
  return context;
}

function FilterBar<T>({
  children,
  data,
  sortBy,
  filters,
  customControl,
  searchFields,
  id,
}: FilterBarProps<T>) {
  const [sort, setSort] = useState<{
    sortBy: SortByItem<T>;
    reverse: boolean;
  } | null>(null);
  const [filteredData, setFilteredData] = useState<T[]>([]);
  const [currentFilterResult, setCurrentFilteredResult] = useState<T[]>([]);

  const [filtersWithKey, setFiltersWithKey] = useState<ColumnFilterWithKey[]>(
    []
  );

  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [appliedFilterState, setAppliedFilterState] = useSessionState<
    Record<string, Filter>
  >({}, id);
  const [filterState, setFilterState] = useState<Record<string, Filter>>(() => {
    return { ...appliedFilterState };
  });

  useEffect(() => {
    setFiltersWithKey(
      filters?.map((filter, index) => ({
        ...filter,
        id: filter.id || filter.name + index,
      })) || []
    );
  }, [filters]);

  const onFilterChange = useCallback((filter: Filter) => {
    setFilterState((oldFilterState) => {
      return {
        ...oldFilterState,
        [filter.id]: filter,
      };
    });
  }, []);

  const filterData = useCallback(
    (
      filter: Record<string, Filter>,
      sort: {
        sortBy: SortByItem<T>;
        reverse: boolean;
      } | null
    ) => {
      let filtered = [...data];
      const filters = Object.values(filter) || [];
      filters.forEach((filter) => {
        filtered = filtered.filter((obj) => {
          if (hasOwnProperty(obj, filter.propertyName)) {
            return filterFunctions[filter.filterFunction](
              obj[filter.propertyName] as any,
              filter.state
            );
          }
          return false;
        });
      });
      if (searchValue && searchFields) {
        filtered = filtered.filter((obj) => {
          let found = false;
          searchFields.forEach((propertyName) => {
            if (hasOwnProperty(obj, propertyName)) {
              if (obj[propertyName] && typeof obj[propertyName] === 'string') {
                let value = obj[propertyName] as unknown as string;
                if (value.toLowerCase().includes(searchValue.toLowerCase()))
                  found = true;
              }
            }
          });
          return found;
        });
      }
      if (sort) {
        filtered.sort(
          typeof sort.sortBy.compareTo === 'function'
            ? sort.sortBy.compareTo
            : defaultComparer(sort.sortBy.key)
        );
        if (sort.reverse) filtered = filtered.reverse();
      }
      return filtered;
    },
    [data, searchValue, searchFields]
  );

  useEffect(() => {
    let filtered = filterData(appliedFilterState, sort);
    setFilteredData([...filtered]);
  }, [appliedFilterState, filterData, sort]);

  useEffect(() => {
    let filtered = filterData(filterState, null);
    setCurrentFilteredResult([...filtered]);
  }, [filterState, filterData]);

  const applyFilter = useCallback(() => {
    setAppliedFilterState({ ...filterState });
  }, [filterState, setAppliedFilterState]);

  const deleteFilter = useCallback(
    (id: string, apply?: boolean) => {
      setFilterState((old) => {
        let newFilterObject = { ...old };
        delete newFilterObject[id];
        if (apply) setAppliedFilterState({ ...newFilterObject });
        return newFilterObject;
      });
    },
    [setAppliedFilterState]
  );

  const clearFilter = useCallback(() => {
    setFilterState({});
    setAppliedFilterState({});
    setSearchValue(null);
  }, [setAppliedFilterState]);

  const resetFilter = useCallback(() => {
    setFilterState(appliedFilterState);
  }, [appliedFilterState]);

  const contextValue = useMemo<IFilterBarContext>(() => {
    return {
      data: data,
      filterState: filterState,
      appliedFilterState: appliedFilterState,
      setFilter: onFilterChange,
      setFilterState: setFilterState,
      deleteFilter,
      applyFilter: applyFilter,
      clear: clearFilter,
      reset: resetFilter,
      searchValue,
      setSearchValue,
      currentFilterResult,
    };
  }, [
    data,
    filterState,
    appliedFilterState,
    onFilterChange,
    setFilterState,
    deleteFilter,
    applyFilter,
    clearFilter,
    resetFilter,
    searchValue,
    setSearchValue,
    currentFilterResult,
  ]);

  return (
    <>
      <FilterBarContext.Provider value={contextValue}>
        <Box
          sx={{
            display: 'flex',
            marginBottom: 1,
            width: '100%',
            alignItems: 'stretch',
            minHeight: '3em',
          }}
        >
          {sortBy && (
            <Box marginRight={1} height="100%" minWidth={'10em'}>
              <Select
                fullWidth
                displayEmpty
                value={
                  sort ? sort.sortBy.key + (sort.reverse ? 'DESC' : 'ASC') : ''
                }
                sx={{
                  color: 'gray',
                  fontStyle: 'normal',
                  borderColor: 'lightgrey',
                  bgcolor: 'white',
                  height: '36px',
                }}
                renderValue={(value: string) => (
                  <Box display={'flex'} alignItems="center">
                    {sort?.reverse === false && (
                      <ArrowUpwardIcon
                        sx={{ color: 'gray', fontSize: '1em' }}
                      />
                    )}
                    {sort?.reverse === true && (
                      <ArrowDownwardIcon
                        sx={{ color: 'gray', fontSize: '1em' }}
                      />
                    )}
                    {!sort && <SortIcon fontSize="small" />}
                    <Typography marginLeft={1}>
                      {sort?.sortBy.title || 'Sortierung'}
                    </Typography>
                  </Box>
                )}
              >
                <MenuItem value={''} onClick={() => setSort(null)}>
                  Keine Sortierung
                </MenuItem>
                {sortBy.map((sortItem, index) => [
                  <MenuItem
                    value={sortItem.key + 'DESC'}
                    key={index + 'DESC'}
                    onClick={() => setSort({ sortBy: sortItem, reverse: true })}
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <ArrowDownwardIcon
                        sx={{ color: 'gray', fontSize: '1em', marginRight: 1 }}
                      />
                      {sortItem.title}
                    </Box>
                  </MenuItem>,
                  <MenuItem
                    value={sortItem.key + 'ASC'}
                    key={index + 'ASC'}
                    onClick={() =>
                      setSort({ sortBy: sortItem, reverse: false })
                    }
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <ArrowUpwardIcon
                        sx={{ color: 'gray', fontSize: '1em', marginRight: 1 }}
                      />
                      {sortItem.title}
                    </Box>
                  </MenuItem>,
                ])}
              </Select>
            </Box>
          )}
          {filtersWithKey && (
            <>
              {filtersWithKey.map((filter, index) => (
                <FilterMenuButton
                  key={filter.id + '-button-' + index}
                  filter={filter}
                />
              ))}
              <FilterDialog filters={filtersWithKey} />
            </>
          )}
          {searchFields && (
            <Box marginLeft={1}>
              <FilterSearchInput />
            </Box>
          )}

          <Box marginLeft={1}>
            <FilterClearButton showIcon />
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          <Box>{customControl && customControl(contextValue)}</Box>
        </Box>
        <Divider />
        <Box marginTop={1} marginBottom={3}>
          {Object.values(appliedFilterState).map((filter, index) => (
            <Chip
              key={filter.id + '-chip-' + index}
              sx={{ marginRight: 1 }}
              label={filter.filterText}
              onDelete={() => {
                deleteFilter(filter.id, true);
              }}
              color="secondary"
              size="small"
            />
          ))}
        </Box>
        {children && children(filteredData)}
      </FilterBarContext.Provider>
    </>
  );
}

export { FilterBar, useFilterBarContext };
