import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import './i18n';

ReactDOM.render(
  <Router>
    <HelmetProvider>
      <AuthProvider>
        <SnackbarProvider maxSnack={3}>
          <App />
        </SnackbarProvider>
      </AuthProvider>
    </HelmetProvider>
  </Router>,

  document.getElementById('root')
);
