import { Box } from '@mui/material';
import { useContext } from 'react';
import DropDownInput, { Option } from '../../inputs/DropDownInput';
import { FilterBarContext, FilterInputProps } from './FilterBar';

const AdaptiveFilter = ({ ...props }: FilterInputProps) => {
  const filterContext = useContext(FilterBarContext);
  const options: Option[] = filterContext.data
    .map((x) => x[props.propertyName])
    .filter((value: any, index: number, self: any) => {
      return self.indexOf(value) === index;
    })
    .map((value) => {
      return {
        key: value,
        text: value,
      };
    });

  const setFilter = (value: string | string[] | null) => {
    const stringArray = value as string[] | null;
    if (stringArray && stringArray.length > 0) {
      filterContext.setFilter({
        filterFunction: 'INCLUDES',
        state: stringArray,
        filterText: props.heading + ': ' + stringArray,
        propertyName: props.propertyName,
        id: props.id,
      });
    } else {
      filterContext.deleteFilter(props.id);
    }
  };
  return (
    <>
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          // minWidth: '15em',
          // maxWidth: '17em',
        }}
      >
        <DropDownInput
          multiple
          items={options}
          label={props.heading}
          handleChange={setFilter}
          error=""
          value={filterContext.filterState[props.id]?.state || []}
        />
      </Box>
    </>
  );
};
export default AdaptiveFilter;
