import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import { IconButton, Typography } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { Box, styled } from '@mui/system';
import React, { ReactNode, useMemo, useState } from 'react';
import { Guardable } from '../../contexts/AuthorizationContext';
import Guarded from '../auth/Guarded';
import { useAccordionContext } from './AccordionForm';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(
  ({
    collapsible,
    ...props
  }: AccordionSummaryProps & { collapsible: boolean }) => (
    <MuiAccordionSummary
      expandIcon={
        collapsible && <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
      }
      {...props}
    />
  )
)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface IAccordionItemContext {
  accordionItemId: string | false;
  hasDirtyFields: boolean;
  setHasDirtyFields: (hasDirtyFields: boolean) => void;
}

const AccordionItemContext = React.createContext<IAccordionItemContext>({
  accordionItemId: false,
  hasDirtyFields: false,
  setHasDirtyFields: () => {},
});

function useAccordionItemContext() {
  const context = React.useContext(AccordionItemContext);
  return context;
}

type AccordionItemProps = {
  children: ReactNode;
  title: string;
  id: string;
} & Guardable;

const AccordionItem = ({
  children,
  title,
  id,
  requiredPermissions,
  projectId,
}: AccordionItemProps) => {
  const {
    editItemId,
    setEditItemId,
    expandedItemId,
    setExpandedItemId,
    collapsible,
  } = useAccordionContext();
  const [accordionItemId] = useState<string | false>(id);
  const [hasDirtyFields, setHasDirtyFields] = useState<boolean>(false);
  const contextValue = useMemo(
    () => ({
      accordionItemId,
      hasDirtyFields,
      setHasDirtyFields,
    }),
    [accordionItemId, hasDirtyFields]
  );

  const expanded = id === expandedItemId;
  const isInEditMode = id === editItemId;
  const disableEdit =
    (editItemId !== false && editItemId !== id) || hasDirtyFields;

  const handleExpand = (newExpanded: boolean) => {
    if (editItemId === false) {
      setExpandedItemId(newExpanded ? id : false);
    }
  };

  const handleEdit = () => {
    if (editItemId === false) {
      setExpandedItemId(id);
    }
    setEditItemId(isInEditMode ? false : id);
  };

  return (
    <Accordion
      expanded={expanded || isInEditMode || !collapsible}
      onChange={(e, newExpanded: boolean) => {
        e.stopPropagation();
        handleExpand(newExpanded);
      }}
    >
      <AccordionSummary collapsible={collapsible}>
        <Box
          flex="1"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{title}</Typography>
          <Box display="flex" alignItems="center">
            {hasDirtyFields && (
              <Typography variant={'subtitle2'}>
                Das Formular enthält nicht gespeicherte Änderungen
              </Typography>
            )}
            <Guarded
              requiredPermissions={requiredPermissions || []}
              projectId={projectId}
              elseLock
            >
              <IconButton
                aria-label="edit"
                size="small"
                disabled={disableEdit}
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit();
                }}
              >
                {isInEditMode && <EditOffIcon fontSize="inherit" />}
                {!isInEditMode && <EditIcon fontSize="inherit" />}
              </IconButton>
            </Guarded>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionItemContext.Provider value={contextValue}>
        <AccordionDetails>{children}</AccordionDetails>
      </AccordionItemContext.Provider>
    </Accordion>
  );
};

interface AccordionReadProps {
  children: JSX.Element;
  id?: string;
}
function Read({ children, id }: AccordionReadProps) {
  const { editItemId } = useAccordionContext();
  const { accordionItemId } = useAccordionItemContext();
  const isInEditMode = accordionItemId === editItemId;

  return !isInEditMode ? children : null;
}

interface AccordionFormProps {
  children: JSX.Element;
  id?: string;
}
function Form({ children }: AccordionFormProps) {
  const context = useAccordionContext();
  const { accordionItemId } = useAccordionItemContext();
  const isInEditMode = accordionItemId === context.editItemId;
  return isInEditMode ? children : null;
}

AccordionItem.Read = Read;
AccordionItem.Form = Form;

export { AccordionItem, useAccordionItemContext };
