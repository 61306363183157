import DecimalDisplay from './DecimalDisplay';
import EmptyNumberDisplay from './EmptyNumberDisplay';

interface IntegerDisplayProps {
  value?: string | number | null;
  minDigits?: number;
}

const IntegerDisplay = ({ value, minDigits }: IntegerDisplayProps) => {
  if (!value) return <EmptyNumberDisplay />;

  return (
    <DecimalDisplay
      value={value}
      maximumFractionDigits={0}
      minimumIntegerDigits={minDigits}
      useGrouping={false}
    />
  );
};

export default IntegerDisplay;
