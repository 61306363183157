import type { FC } from 'react';
import Page from '../../common/layout/Page';
import DashboardGridLayout from '../../domain/dashboard/DashboardGridLayout';
import useProject from '../../hooks/useProject';

const ProjectDashboard: FC = () => {
  const project = useProject();
  return (
    <Page
      name="Dashboard"
      title={`Dashboard | ${project?.bez || ''}`}
      breadcrumbItems={[
        { to: `/projects/${project?.id}`, name: project?.kurzbez || '' },
        { name: 'Dashboard' },
      ]}
    >
      <DashboardGridLayout />
    </Page>
  );
};

export default ProjectDashboard;
