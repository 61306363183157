import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Guarded from './common/auth/Guarded';
import AuthGuard from './domain/AuthGuard';
import Layout from './common/layout/Layout';
import Login from './pages/authentication/Login';
import ProjectAnalysis from './pages/project/ProjectAnalysis';
import ProjectBilling from './pages/project/ProjectBilling';
import ProjectCostplanning from './pages/project/ProjectCostplanning';
import ProjectFormulartest from './pages/project/ProjectFormulartest';
import ProjectOrderEdit from './pages/project/ProjectOrderEdit';
import ProjectOrders from './pages/project/ProjectOrders';
import ProjectDashboard from './pages/project/ProjectDashboard';
import ProjectReports from './pages/project/ProjectReports';
import NoPermission from './pages/NoPermission';
import Projects from './pages/Projects';
import MasterData from './pages/MasterData';
import Users from './pages/Users';
import Reports from './pages/Reports';

const Routes: FC = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/projects" />
        </Route>
        <Route path="/login" component={Login} exact />
      </Switch>
      <AuthGuard>
        <Switch>
          <Route
            path={['/projects']}
            render={() => (
              <Layout>
                <Projects />
              </Layout>
            )}
            exact
          />
          <Route
            path={['/stammdaten']}
            render={() => (
              <Layout>
                <MasterData />
              </Layout>
            )}
            exact
          />
          <Route
            path={['/benutzer']}
            render={() => (
              <Layout>
                <Users />
              </Layout>
            )}
            exact
          />
          <Route
            path={['/berichte']}
            render={() => (
              <Layout>
                <Reports />
              </Layout>
            )}
            exact
          />
          <Route
            path="/projects/:projectId"
            render={(props) => (
              <Guarded
                requiredPermissions={['ProjektOeffnen']}
                projectId={props.match.params.projectId}
                elseRender={
                  <Layout>
                    <NoPermission />
                  </Layout>
                }
              >
                <Layout>
                  <Switch>
                    <Route
                      path="/projects/:projectId"
                      exact
                      render={(props) => (
                        <Redirect
                          to={`/projects/${props.match.params.projectId}/dashboard`}
                        />
                      )}
                    ></Route>
                    <Route
                      path={['/projects/:projectId/dashboard']}
                      component={ProjectDashboard}
                      exact
                    />
                    <Route
                      path={['/projects/:projectId/costs']}
                      component={ProjectCostplanning}
                      exact
                    />
                    <Route
                      path={['/projects/:projectId/orders']}
                      component={ProjectOrders}
                      exact
                    />
                    <Route
                      path={['/projects/:projectId/orders/:orderId']}
                      component={ProjectOrderEdit}
                      exact
                    />
                    <Route
                      path={['/projects/:projectId/invoices']}
                      component={ProjectBilling}
                      exact
                    />
                    <Route
                      path={['/projects/:projectId/analysis']}
                      component={ProjectAnalysis}
                      exact
                    />
                    <Route
                      path={['/projects/:projectId/reports']}
                      component={ProjectReports}
                      exact
                    />
                    <Route
                      path={['/projects/:projectId/formulartest']}
                      component={ProjectFormulartest}
                      exact
                    />
                  </Switch>
                </Layout>
              </Guarded>
            )}
          />
        </Switch>
      </AuthGuard>
    </>
  );
};

export default Routes;
