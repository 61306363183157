import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
  ServerError,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { CssBaseline, ThemeProvider } from '@mui/material';
import deLocale from 'date-fns/locale/de';
import { FC } from 'react';
import { AuthorizationProvider } from './contexts/AuthorizationContext';
import SplashScreen from './domain/SplashScreen';
import useAuth from './hooks/useAuth';
import useAuthorization from './hooks/useAuthorization';
import useSettings from './hooks/useSettings';
import Routes from './Routes';
import { createCustomTheme } from './theme';

const App: FC = () => {
  const { settings } = useSettings();
  const auth = useAuth();
  const { clearPermissions } = useAuthorization();

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_GQL_API,
  });

  const logoutLink = onError(({ networkError }) => {
    if ((networkError as ServerError)?.statusCode === 401) {
      auth.logout();
      clearPermissions();
    }
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = sessionStorage.getItem('accessToken');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const client = new ApolloClient({
    link: from([logoutLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  // Wrap useAuthorization as component, so that the call
  // is from a child of AuthorizationProvider.
  const AuthorizedLayout = () => {
    const { loading } = useAuthorization();
    return auth.isInitialized && !loading ? <Routes /> : <SplashScreen />;
  };

  return (
    <ApolloProvider client={client}>
      <AuthorizationProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
            <CssBaseline />
            <AuthorizedLayout />
          </LocalizationProvider>
        </ThemeProvider>
      </AuthorizationProvider>
    </ApolloProvider>
  );
};

export default App;
