import { NumberFormatValues } from 'react-number-format';
import DecimalInput from './DecimalInput';

export type IntegerInputProps = {
  handleChange: (value: NumberFormatValues) => void;
  handleBlur?: () => void;
  value: number | null;
  label?: string;
  error?: string;
  showThousandSeperator?: boolean;
  limitRerender?: boolean;
};

const IntegerInput = ({
  label,
  value,
  error,
  showThousandSeperator = false,
  handleChange,
  handleBlur,
  limitRerender,
}: IntegerInputProps) => {
  return (
    <DecimalInput
      label={label}
      value={value}
      handleChange={handleChange}
      handleBlur={handleBlur}
      showThousandSeperator={showThousandSeperator}
      decimalScale={0}
      error={error}
      InputProps={{ inputProps: { min: 0, max: 10 } }}
      limitRerender={limitRerender}
    />
  );
};

export default IntegerInput;
