import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import type { FC } from 'react';
import { useParams } from 'react-router';
import { AccordionForm } from '../../common/form/AccordionForm';
import { AccordionItem } from '../../common/form/AccordionItem';
import Page from '../../common/layout/Page';
import BasicDataDisplay from '../../domain/order/BasicDataDisplay';
import BasicDataForm from '../../domain/order/BasicDataForm';
import useProject from '../../hooks/useProject';
import { GetOrder } from '../../schema/GetOrder';
import { Order } from '../../schema/Order';

const GET_ORDERS = gql`
  fragment Order on Auftrag {
    projektId
    auftragsnummer
    art
    auftragnehmer
    leistungsbez
    auftragssumme
    auftragsdatum
    leistungsbeschreibung
    angebotsdatum
    gewerk
    strasse
    plz
    ort
    email
    telefon
    id
  }

  query GetOrder {
    demo {
      alleAuftraege {
        ...Order
      }
    }
  }
`;

const ProjectOrderEdit: FC = () => {
  const params: {
    orderId: string;
  } = useParams();

  const project = useProject();
  const orderId = params.orderId;
  const { data, refetch } = useQuery<GetOrder>(GET_ORDERS);

  const orders: Order[] = (data?.demo?.alleAuftraege || []).filter(
    (x): x is Order => x !== null && x !== undefined
  );

  const order = orders.find((item) => item.id === orderId);
  if (order === undefined) return <></>;

  return (
    <Page
      name="Aufträge"
      title={`Bestellung bearbeiten | ${order?.auftragsnummer || ''}`}
      breadcrumbItems={[
        { to: `/projects/${project?.id}`, name: project?.kurzbez || '' },
        {
          to: `/projects/${project?.id}/orders`,
          name: 'Aufräge',
        },
        { name: order.leistungsbez || '' },
      ]}
    >
      <AccordionForm initialExpandedId="test1">
        <AccordionItem
          title="Basisdaten"
          id="test1"
          requiredPermissions={['RechnungBearbeiten']}
          projectId={project?.id}
        >
          <AccordionItem.Form>
            <BasicDataForm order={order} refetch={refetch} />
          </AccordionItem.Form>
          <AccordionItem.Read>
            <BasicDataDisplay order={order} />
          </AccordionItem.Read>
        </AccordionItem>
        <AccordionItem title="Beispielformular" id="test2">
          <AccordionItem.Form>
            <BasicDataForm order={order} refetch={refetch} />
          </AccordionItem.Form>
          <AccordionItem.Read>
            <BasicDataDisplay order={order} />
          </AccordionItem.Read>
        </AccordionItem>
      </AccordionForm>
    </Page>
  );
};

export default ProjectOrderEdit;
