import { useQuery } from '@apollo/client';
import PlusIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import gql from 'graphql-tag';
import { FC, useState } from 'react';
import Page from '../../common/layout/Page';
import OrderCreateModal from '../../domain/order/OrderCreateModal';
import OrderListTable from '../../domain/order/OrderListTable';
import useProject from '../../hooks/useProject';
import { GetOrders } from '../../schema/GetOrders';
import { Order } from '../../schema/Order';

const GET_ORDERS = gql`
  fragment Order on Auftrag {
    projektId
    auftragsnummer
    art
    auftragnehmer
    leistungsbez
    auftragssumme
    auftragsdatum
    leistungsbeschreibung
    angebotsdatum
    gewerk
    strasse
    plz
    ort
    email
    telefon
    id
  }

  query GetOrders {
    demo {
      alleAuftraege {
        ...Order
      }
    }
  }
`;

const ProjectOrders: FC = () => {
  const { data, loading, error, refetch } = useQuery<GetOrders>(GET_ORDERS);

  const orders: Order[] = (data?.demo?.alleAuftraege || []).filter(
    (x): x is Order => x !== null && x !== undefined
  );

  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    refetch();
    setOpen(false);
  };

  const project = useProject();

  return (
    <Page
      name="Aufträge"
      title={`Aufträge | ${project?.bez || ''}`}
      breadcrumbItems={[
        { to: `/projects/${project?.id}`, name: project?.kurzbez || '' },
        { name: 'Aufträge' },
      ]}
      buttons={() => (
        <>
          {project?.id && (
            <Button
              color="primary"
              startIcon={<PlusIcon fontSize="small" />}
              sx={{ m: 1 }}
              variant="contained"
              onClick={handleOpen}
            >
              Auftrag anlegen
            </Button>
          )}
        </>
      )}
    >
      {loading && <p>Loading ...</p>}
      {error && <p>Error :(</p>}
      {data && <OrderListTable orders={orders} />}
      {project?.id && (
        <OrderCreateModal
          onClose={handleClose}
          open={open}
          projektId={project.id}
        />
      )}
    </Page>
  );
};

export default ProjectOrders;
