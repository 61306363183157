import { Box, Grid, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import Breadcrumb from '../navigation/Breadcrumb';
import { BreadcrumbItemProps } from '../navigation/BreadcrumbItem';

interface PageHeaderProps {
  children?: ReactNode;
  name: string;
  breadcrumbItems?: BreadcrumbItemProps[];
}

const PageHeader: FC<PageHeaderProps> = ({
  children,
  name,
  breadcrumbItems,
}) => {
  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item>
        {breadcrumbItems && <Breadcrumb items={breadcrumbItems} />}
        <Typography marginTop={2} color="textPrimary" variant="h5">
          {name}
        </Typography>
      </Grid>
      <Grid item>
        <Box sx={{ m: -1 }}>{children}</Box>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
