import gql from 'graphql-tag';
import { FC } from 'react';
import FormModal from '../../common/form/FormModal';
import FormSection from '../../common/form/FormSection';
import FormTextInput from '../../common/inputs/FormTextInput';
import { AuftragInput } from '../../schema/globalTypes';

interface OrderCreateModalProps {
  projektId: string;
  open: boolean;
  onClose?: () => void;
}

const CREATE_ORDER = gql`
  mutation CreateOrder($auftragData: AuftragInput!) {
    demo {
      createAuftrag(auftragData: $auftragData) {
        projektId
        auftragsnummer
        auftragnehmer
      }
    }
  }
`;

const OrderCreateModal: FC<OrderCreateModalProps> = (props) => {
  const { projektId, onClose, open } = props;

  return (
    <FormModal<AuftragInput, any, any>
      title="Auftrag anlegen"
      mutation={CREATE_ORDER}
      initialValues={{
        projektId,
        auftragsnummer: 1,
        auftragnehmer: '',
      }}
      open={open}
      makeVars={(auftragData) => ({ auftragData })}
      refetch={() => {}}
      onClose={onClose}
    >
      <FormSection xs={12}>
        <FormTextInput name="auftragsnummer" label="Auftragsnummer" />
        <FormTextInput name="auftragnehmer" label="Auftragnehmer" />
      </FormSection>
    </FormModal>
  );
};

export default OrderCreateModal;
