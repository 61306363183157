import { Box } from '@mui/material';
import { useContext } from 'react';
import BooleanInput from '../../inputs/BooleanInput';
import { FilterBarContext, FilterInputProps } from './FilterBar';

const BooleanFilter = ({ ...props }: FilterInputProps) => {
  const filterContext = useContext(FilterBarContext);

  const setFilter = (value: boolean) => {
    filterContext.setFilter({
      filterFunction: 'EQUAL',
      state: value,
      filterText: props.heading,
      propertyName: props.propertyName,
      id: props.id,
    });
  };
  return (
    <>
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          minWidth: '15em',
          maxWidth: '17em',
        }}
      >
        <BooleanInput
          label={props.heading}
          handleChange={setFilter}
          value={filterContext.filterState[props.id]?.state}
        />
      </Box>
    </>
  );
};
export default BooleanFilter;
