import React, { ReactNode, useMemo, useState } from 'react';

interface IAccordionContext {
  editItemId: string | false;
  expandedItemId: string | false;
  setEditItemId: (id: string | false) => void;
  setExpandedItemId: (id: string | false) => void;
  collapsible: boolean;
}

const AccordionContext = React.createContext<IAccordionContext>({
  editItemId: false,
  expandedItemId: false,
  setEditItemId: () => {},
  setExpandedItemId: () => {},
  collapsible: true,
});

function useAccordionContext() {
  const context = React.useContext(AccordionContext);
  if (!context) {
    throw new Error(
      `Accordion compound components cannot be rendered outside the Accordion component`
    );
  }
  return context;
}

interface AccordionFormProps {
  children: ReactNode | ReactNode[];
  initialExpandedId?: string;
}
const AccordionForm = ({ children, initialExpandedId }: AccordionFormProps) => {
  const [editItemId, setEditItemId] = useState<string | false>(false);
  const [expandedItemId, setExpandedItemId] = useState<string | false>(
    initialExpandedId || false
  );

  const value = useMemo(
    () => ({
      editItemId,
      expandedItemId,
      setEditItemId,
      setExpandedItemId,
      collapsible: (Array.isArray(children) ? children : []).length > 1,
    }),
    [editItemId, expandedItemId, children]
  );
  return (
    <AccordionContext.Provider value={value}>
      {children}
    </AccordionContext.Provider>
  );
};

export { AccordionForm, useAccordionContext };
