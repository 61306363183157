import {
  Chip,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, SxProps, Theme } from '@mui/system';
import { useEffect, useState } from 'react';
import PivotControl, { PivotControlState } from './PivotControl';

interface RowProps<T> {
  row: Record<string, any>;
  level: number;
  columns: PivotTableColumn<T>[];
  showData: boolean;
  groupColumnSx?: SxProps<Theme>;
  index: number;
  showCount: boolean;
  isOpen: boolean;
  selectedIndex: number | null;
  offSet: number;
}

const Row = <T,>({
  row,
  level,
  columns = [],
  showData,
  index,
  groupColumnSx,
  showCount,
  isOpen,
  selectedIndex,
  offSet,
}: RowProps<T>) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(isOpen || false);
  }, [isOpen]);

  return (
    <>
      {row && ('rows' in row || showData) && (
        <>
          <TableRow
            sx={
              !('rows' in row) && showData && selectedIndex === index + offSet
                ? { bgcolor: 'lightgrey' }
                : {}
            }
          >
            <TableCell sx={groupColumnSx}>
              {'rows' in row && row.rows && (
                <Box
                  sx={{
                    paddingLeft: level * 2,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {row.field && (row.rows[0].field || showData) && (
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                      sx={{ padding: 0 }}
                    >
                      {open ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      )}
                    </IconButton>
                  )}
                  {row.field && !(row.rows[0].field || showData) && (
                    <Box marginRight={3} />
                  )}
                  <Chip
                    color={row.field.color}
                    label={`${row.value} ${
                      showCount ? `(${row.rows.length})` : ''
                    }`}
                    size="small"
                  />
                </Box>
              )}
            </TableCell>
            {columns &&
              columns.map((column, columnIndex) => {
                if (column.displayComponent) {
                  return (
                    <TableCell
                      key={
                        'pivot-table-row-cell' +
                        level +
                        '-' +
                        index +
                        '-' +
                        columnIndex
                      }
                      sx={{ ...column.sx }}
                    >
                      {column.displayComponent(row[column.key])}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={
                        'pivot-table-row-cell' +
                        level +
                        '-' +
                        index +
                        '-' +
                        columnIndex
                      }
                    >
                      {row[column.key]}
                    </TableCell>
                  );
                }
              })}
          </TableRow>
          {open &&
            row &&
            'rows' in row &&
            row.rows.map(
              (
                row: Record<string, any>,
                index: number,
                array: Record<string, any>[]
              ) => {
                let isOpen = false;
                if ('offSet' in row && 'count' in row) {
                  isOpen =
                    selectedIndex !== null
                      ? selectedIndex >= row.offSet &&
                        selectedIndex < row.offSet + row.count
                      : false;
                }

                return (
                  <Row<T>
                    row={row}
                    level={level + 1}
                    columns={columns}
                    showData={showData}
                    index={index}
                    showCount={showCount}
                    key={'pivot-row-' + (level + 1) + '-' + index}
                    isOpen={isOpen}
                    selectedIndex={selectedIndex}
                    offSet={row.offSet || offSet}
                  />
                );
              }
            )}
        </>
      )}
    </>
  );
};

function groupBy<T>(
  arr: T[],
  fields: GroupField<T>[],
  aggregations: PivotTableColumn<T>[] = [],
  offSet: number
) {
  let field = fields[0];
  if (!field) return arr;
  let retArr: Grouped<T>[] = Object.values<Grouped<T>>(
    arr.reduce(
      (
        obj: Record<string, any>,
        current: Record<string, any>,
        currentIndex
      ) => {
        if (!obj[current[field.name]]) {
          obj[current[field.name]] = {
            field: field,
            color: field.color,
            value: field.name === 'root' ? 'Gesamt' : current[field.name],
            rows: [],
          };
        }
        obj[current[field.name]].rows.push(current);

        aggregations.forEach((a) => {
          if (a.aggregateFunction) {
            obj[current[field.name]][a.key] = a.aggregateFunction(
              obj[current[field.name]][a.key] || 0,
              current[a.key]
            );
          }
        });
        return obj;
      },
      {}
    )
  );
  if (fields.length) {
    retArr.forEach((obj: any, index, array: any[]) => {
      obj.count = obj.rows.length;
      obj.offSet =
        index === 0 ? offSet : array[index - 1].offSet + array[index - 1].count;
      obj.rows = groupBy(obj.rows, fields.slice(1), aggregations, obj.offSet);
    });
  }

  return retArr;
}

interface Grouped<T> {
  field: string;
  value: string | number | boolean;
  color: PivotColor;
  aggregation: number;
  rows: Grouped<T>[] | T[];
  offSet: number;
  count: number;
}

export interface PivotTableColumn<T> {
  heading: string;
  key: keyof T & string;
  aggregateFunction?: (prev: any, current: any) => any;
  displayComponent?: (props: any) => JSX.Element;
  sx?: SxProps<Theme>;
}
type PivotColor =
  | 'secondary'
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'
  | 'default'
  | 'pivotRed'
  | 'pivotIndigo'
  | 'pivotLightBlue'
  | 'pivotTeal'
  | 'pivotLightGreen'
  | 'pivotBlueGrey';

export interface GroupField<T> {
  name: (keyof T & string) | 'root';
  color?: PivotColor & string;
}
interface PivotTableProps<T> {
  data: T[];
  columns: PivotTableColumn<T>[];
  groupFields: GroupField<T>[];
  showDataRecords?: boolean;
  groupHeading: string;
  groupColumnSx?: SxProps<Theme>;
  showControl: boolean;
  searchFields: string[];
}

function hasOwnProperty<X extends {}, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return obj.hasOwnProperty(prop);
}

const PivotTable = <T,>({
  data,
  columns,
  groupFields,
  groupHeading,
  groupColumnSx,
  showControl,
  searchFields,
}: PivotTableProps<T>) => {
  const [grouped, setGrouped] = useState<T[] | Grouped<T>[]>([]);
  const [controlState, setControlState] = useState<PivotControlState>({
    selected: [],
    unselected: [],
    showCount: false,
    showData: false,
    showRoot: false,
    searchValue: null,
    searchResultCount: null,
    selectedIndex: null,
  });

  useEffect(() => {
    let colors: PivotColor[] = [
      'pivotIndigo',
      'pivotLightBlue',
      'pivotTeal',
      'pivotLightGreen',
      'pivotBlueGrey',
      'pivotRed',
      'secondary',
      'primary',
      'info',
      'success',
      'warning',
      'default',
    ];
    const existingColors: PivotColor[] = groupFields.map(
      (field) => field.color || 'default'
    );
    colors = colors.filter((c) => !existingColors.includes(c));
    const colored = groupFields.map((field) => ({
      ...field,
      color: field.color || colors.splice(0, 1)[0] || 'default',
    }));
    setControlState((state) => ({ ...state, selected: colored }));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    const aggregates = columns.filter(
      (c: PivotTableColumn<T>) => c.aggregateFunction
    );
    let filtered = data;
    if (searchFields && controlState.searchValue) {
      filtered = filtered.filter((obj) => {
        let found = false;
        searchFields.forEach((propertyName) => {
          if (hasOwnProperty(obj, propertyName)) {
            if (obj[propertyName] && typeof obj[propertyName] === 'string') {
              let value = obj[propertyName] as unknown as string;
              if (
                controlState.searchValue &&
                value
                  .toLowerCase()
                  .includes(controlState.searchValue.toLowerCase())
              )
                found = true;
            }
          }
        });
        return found;
      });
    }
    setControlState((old) => ({ ...old, searchResultCount: filtered.length }));
    const newGrouped = groupBy<T>(
      filtered,
      controlState.showRoot
        ? [{ name: 'root', color: 'default' }, ...controlState.selected]
        : controlState.selected,
      aggregates,
      0
    );
    setGrouped(newGrouped);
  }, [
    data,
    columns,
    searchFields,
    controlState.searchValue,
    controlState.selected,
    controlState.selected.length,
    controlState.showRoot,
  ]);

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        {showControl && (
          <Paper
            sx={{
              marginRight: 2,
              height: '100%',
              minWidth: '20rem',
              width: '20rem',
            }}
          >
            <PivotControl state={controlState} onChange={setControlState} />
          </Paper>
        )}

        <TableContainer component={Paper} sx={{ height: '100%' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>{groupHeading}</TableCell>
                {columns &&
                  columns.map((c, index) => (
                    <TableCell
                      key={'pivot-table-heading-' + c.heading + '-' + index}
                    >
                      {c.heading}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {grouped &&
                grouped.map((row, index, array) => {
                  let isOpen = false;
                  if ('offSet' in row && 'count' in row) {
                    isOpen =
                      controlState.selectedIndex !== null
                        ? controlState.selectedIndex >= row.offSet &&
                          controlState.selectedIndex < row.offSet + row.count
                        : false;
                  }
                  return (
                    <>
                      <Row<T>
                        key={'pivot-row-0-' + index}
                        row={row}
                        level={0}
                        columns={columns}
                        index={index}
                        showData={controlState.showData}
                        showCount={controlState.showCount}
                        groupColumnSx={groupColumnSx}
                        isOpen={isOpen}
                        selectedIndex={controlState.selectedIndex}
                        offSet={'offSet' in row ? row.offSet : 0}
                      />
                    </>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
export default PivotTable;
