import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { useRef, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import useAuthorization from '../../hooks/useAuthorization';

const AccountPopover: FC = () => {
  const history = useHistory();
  const client = useApolloClient();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { logout } = useAuth();
  const { clearPermissions } = useAuthorization();
  const [open, setOpen] = useState<boolean>(false);
  const auth = useAuth();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      client.clearStore();
      clearPermissions();
      history.push('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            {auth.user?.name}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
