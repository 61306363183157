import { useState, useEffect, FC } from 'react';
import { CommonWidgetProps } from './Widget';
import BarChart from '../../common/charts/BarChart';

export interface BarChartDataType {
  categories: number[];
  data: number[];
}

export const getBarChartData = (): Promise<BarChartDataType> => {
  return Promise.resolve({
    categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    data: [30, 40, 45, 50, 49, 60, 70, 91],
  });
};

const ExampleBarChartWidget: FC<CommonWidgetProps> = ({ height }) => {
  const [chartData, setChartData] = useState<BarChartDataType>();

  useEffect(() => {
    getBarChartData().then((response) => {
      setChartData(response);
    });
  }, []);

  return <>{chartData && <BarChart height={height} data={chartData} />}</>;
};

export default ExampleBarChartWidget;
