import { Grid } from '@mui/material';
import { FC } from 'react';
import { FormGridProps } from './FormGridProps';
import Label from './Label';

interface FormSectionProps {
  label?: string;
}

const FormSection: FC<FormSectionProps & FormGridProps> = ({
  label,
  children,
  xs = 12,
  ...gridProps
}) => {
  return (
    <>
      <Grid item xs={xs} {...gridProps}>
        <Grid container spacing={3}>
          {label && <Label text={label} />}
          {children}
        </Grid>
      </Grid>
    </>
  );
};

export default FormSection;
