import { FC } from 'react';
import Page from '../../common/layout/Page';
import ExampleAnalysisForm from '../../domain/order/ExampleAnalysisForm';
import useProject from '../../hooks/useProject';

const ProjectAnalysis: FC = () => {
  const project = useProject();

  return (
    <Page
      name="Auswertung"
      title={`Auswertung | ${project?.bez || ''}`}
      breadcrumbItems={[
        { to: `/projects/${project?.id}`, name: project?.kurzbez || '' },
        { name: 'Auswertung' },
      ]}
    >
      <ExampleAnalysisForm />
    </Page>
  );
};

export default ProjectAnalysis;
