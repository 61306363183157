import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
}
const BlockNavigationDialog = ({
  when,
  navigate,
  shouldBlockNavigation,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);
  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      <Dialog open={modalVisible} onClose={closeModal}>
        <DialogTitle>
          Nicht gespeicherte Änderungen: Seite verlassen?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Das Forumlar enthält nicht gespeicherte Änderungen. Wenn sie die
            Seite verlassen, gehen diese Änderungen verloren.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Abbrechen</Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleConfirmNavigationClick}
            autoFocus
          >
            Seite verlassen
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default BlockNavigationDialog;
