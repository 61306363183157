import LockIcon from '@mui/icons-material/Lock';
import { IconButton } from '@mui/material';
import { ReactNode } from 'react';
import { PermissionType } from '../../contexts/AuthorizationContext';
import useAuthorization from '../../hooks/useAuthorization';

interface GuardedProps {
  requiredPermissions: PermissionType[];
  projectId?: string | null;
  elseRender?: ReactNode;
  elseLock?: boolean;
}

const Guarded: React.FC<GuardedProps> = ({
  requiredPermissions,
  projectId: id,
  children,
  elseLock,
  elseRender,
}) => {
  const { checkPermission } = useAuthorization();

  if (checkPermission(requiredPermissions, id)) {
    return <>{children}</>;
  }

  if (elseRender) {
    return <>{elseRender}</>;
  }

  if (elseLock)
    return (
      <IconButton size="small" disabled>
        <LockIcon fontSize="inherit" />
      </IconButton>
    );

  return <></>;
};

export default Guarded;
