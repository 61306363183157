import { useField } from 'formik';
import FormField from '../form/FormField';
import { FormGridProps } from '../form/FormGridProps';
import TextInput from './TextInput';

interface FormTextInputProps {
  name: string;
  label?: string;
  multiline?: boolean;
}

const FormTextInput = ({
  name,
  label,
  multiline,
  xs,
  sm,
  md,
  lg,
  xl,
}: FormTextInputProps & FormGridProps) => {
  const [field, meta, form] = useField<string | null>(name);
  const handleChange = (value: string) => {
    form.setValue(value || null);
  };
  const handleBlur = () => {
    form.setTouched(true);
  };
  return (
    <FormField xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <TextInput
        label={label}
        value={field.value}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={meta.touched && meta.error ? meta.error : undefined}
        multiline={multiline}
        limitRerender
      />
    </FormField>
  );
};

export default FormTextInput;
