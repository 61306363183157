import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { useParams } from 'react-router';
import { CoreProject } from '../schema/CoreProject';
import { GetProjectsCore } from '../schema/GetProjectsCore';

const GET_PROJECTS_CORE = gql`
  fragment CoreProject on Projekt {
    id
    kurzbez
    bez
  }

  query GetProjectsCore {
    demo {
      alleProjekte {
        ...CoreProject
      }
    }
  }
`;

const useProject = (): CoreProject | null => {
  let params: { projectId: string } = useParams();
  const { data } = useQuery<GetProjectsCore>(GET_PROJECTS_CORE);
  return (
    data?.demo?.alleProjekte?.find((p) => {
      return p && p.id === params.projectId;
    }) ?? null
  );
};

export default useProject;
