import { Typography } from '@mui/material';

interface LabelProps {
  text: string;
}

const Label = ({ text }: LabelProps) => (
  <Typography gutterBottom component="div" mr={5} mb={0}>
    {text}
  </Typography>
);

export default Label;
