import { FC } from 'react';
import Page from '../../common/layout/Page';
import useProject from '../../hooks/useProject';

const ProjectBilling: FC = () => {
  const project = useProject();

  return (
    <Page
      name="Rechnungen"
      title={`Rechnungen | ${project?.bez || ''}`}
      breadcrumbItems={[
        { to: `/projects/${project?.id}`, name: project?.kurzbez || '' },
        { name: 'Rechnungen' },
      ]}
    ></Page>
  );
};

export default ProjectBilling;
