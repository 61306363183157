import EmptyNumberDisplay from './EmptyNumberDisplay';
import TextDisplay from './TextDisplay';

interface DecimalDisplayProps {
  value?: number | string | null;
  numberStyle?: string;
}

const DecimalDisplay = ({
  value,
  numberStyle,
  ...numberFormatOptions
}: DecimalDisplayProps & Intl.NumberFormatOptions) => {
  if (!value) return <EmptyNumberDisplay />;

  const formatter = new Intl.NumberFormat('de-DE', {
    ...numberFormatOptions,
    style: numberStyle,
  });

  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  if (!numericValue || isNaN(numericValue)) {
    return <EmptyNumberDisplay />;
  }

  const stringValue = formatter.format(numericValue);
  return <TextDisplay value={stringValue} />;
};

export default DecimalDisplay;
