import { useField } from 'formik';
import FormField from '../form/FormField';
import { FormGridProps } from '../form/FormGridProps';
import DropDownInput, { Option } from './DropDownInput';
interface FormDropDownInputProps {
  name: string;
  label?: string;
  items: Option[];
  optional?: boolean;
  multiple?: boolean;
}

const FormDropDownInput = ({
  optional = false,
  multiple = false,
  ...props
}: FormDropDownInputProps & FormGridProps) => {
  const [field, meta, form] = useField<string | string[] | null>(props.name);
  const handleChange = (value: string | string[] | null) => {
    form.setValue(value);
  };
  const handleBlur = () => {
    form.setTouched(true);
  };
  return (
    <FormField>
      <DropDownInput
        items={props.items}
        label={props.label}
        error={meta.touched && meta.error ? meta.error : undefined}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={field.value}
        optional={optional}
        multiple={multiple}
        limitRerender
      />
    </FormField>
  );
};
export default FormDropDownInput;
