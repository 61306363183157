import { Grid } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import CurrencyDisplay from '../../common/displays/CurrencyDisplay';
import DateDisplay from '../../common/displays/DateDisplay';
import IntegerDisplay from '../../common/displays/IntegerDisplay';
import TextDisplay from '../../common/displays/TextDisplay';
import FormFieldDisplay from '../../common/form/FormFieldDisplay';
import FormSection from '../../common/form/FormSection';
import { Order } from '../../schema/Order';

interface BasicDataDisplayProps {
  children?: ReactNode;
  order: Order;
}
const BasicDataDisplay: FC<BasicDataDisplayProps> = ({ order }) => {
  const { t } = useTranslation();
  if (order === undefined) return <></>;
  return (
    <Grid container spacing={3}>
      <>
        <FormSection>
          <FormFieldDisplay label="Art des Auftrags">
            <TextDisplay value={order.art} />
          </FormFieldDisplay>
        </FormSection>

        <FormSection xs={12} xl={6}>
          <FormFieldDisplay label="Leistungsbezeichnung">
            <TextDisplay value={order.leistungsbez} />
          </FormFieldDisplay>

          <FormFieldDisplay label={t('gewerk')}>
            <TextDisplay value={order.gewerk} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Auftragsnummer" xs={12} xl={6}>
            <IntegerDisplay value={order.auftragsnummer} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Auftragssumme" xs={12} xl={6}>
            <CurrencyDisplay value={order.auftragssumme} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Auftragsdatum" xs={12} xl={6}>
            <DateDisplay date={order.auftragsdatum} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Angebotsdatum" xs={12} xl={6}>
            <DateDisplay date={order.angebotsdatum} />
          </FormFieldDisplay>
        </FormSection>

        <FormSection xs={12} xl={6}>
          <FormFieldDisplay label="Auftragnehmer">
            <TextDisplay value={order.auftragnehmer} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Straße">
            <TextDisplay value={order.strasse} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Postleitzahl" xs={12} xl={3}>
            <TextDisplay value={order.plz} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Ort" xs={12} xl={9}>
            <TextDisplay value={order.ort} />
          </FormFieldDisplay>

          <FormFieldDisplay label="Telefon">
            <TextDisplay value={order.telefon} />
          </FormFieldDisplay>
        </FormSection>

        <FormSection>
          <FormFieldDisplay label="Leistungsbeschreibung">
            <TextDisplay value={order.leistungsbeschreibung} multiline={true} />
          </FormFieldDisplay>

          <FormFieldDisplay label="E-Mail">
            <TextDisplay value={order.email} />
          </FormFieldDisplay>
        </FormSection>
      </>
    </Grid>
  );
};

export default BasicDataDisplay;
