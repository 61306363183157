import { useQuery } from '@apollo/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import gql from 'graphql-tag';
import { FC } from 'react';
import Guarded from '../../common/auth/Guarded';
import { RangeFilter } from '../../common/data/filter/RangeFilter';
import { FilterBar } from '../../common/data/filter/FilterBar';
import TableRowLink from '../../common/table/TableRowLink';
import { GetProjects } from '../../schema/GetProjects';
import { Project } from '../../schema/Project';
import AdaptiveFilter from '../../common/data/filter/AdaptiveFilter';
import DateRangeFilter from '../../common/data/filter/DateRangeFilter';
import BooleanFilter from '../../common/data/filter/BooleanFilter';

interface ProjectListTableProps {
  tableHeight?: number;
}

const ProjectListTable: FC<ProjectListTableProps> = ({ tableHeight }) => {
  const GET_PROJECTS = gql`
    fragment Project on Projekt {
      kurzbez
      bez
      projektsumme
      budget
      hochrechnung
      ort
      bauherr
      aktiv
      baubeginn
      fertigstellung
      id
    }

    query GetProjects {
      demo {
        alleProjekte {
          ...Project
        }
      }
    }
  `;

  const { data, loading, error } = useQuery<GetProjects>(GET_PROJECTS);

  if (loading) return <p>Loading...</p>;
  if (error)
    return (
      <pre>
        Bad:{' '}
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
        {error.networkError?.message}
      </pre>
    );

  const getTableHeight = () => {
    return tableHeight ? (tableHeight * 100 - 120).toString() + 'px' : '';
  };

  const projects: Project[] = (data?.demo?.alleProjekte || []).filter(
    (project): project is Project => project !== null && project !== undefined
  );

  return (
    <>
      <FilterBar
        data={projects}
        sortBy={[
          {
            key: 'bauherr',
            title: 'Bauherr',
          },
          {
            key: 'baubeginn',
            title: 'Baubeginn',
          },
        ]}
        filters={[
          {
            name: 'budget',
            heading: 'Budgetrahmen',
            filterComponent: RangeFilter,
          },
          {
            name: 'bauherr',
            heading: 'Bauherr',
            filterComponent: AdaptiveFilter,
          },
          {
            name: 'baubeginn',
            heading: 'Baubeginn',
            filterComponent: DateRangeFilter,
          },
          {
            name: 'aktiv',
            heading: 'Aktiv',
            filterComponent: BooleanFilter,
          },
        ]}
        searchFields={['bauherr', 'bez']}
        id="projectFilterBar"
      >
        {(filteredProjects) => (
          <>
            <Card>
              <TableContainer style={{ height: getTableHeight() }}>
                <Table sx={{ minWidth: 1150 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Kürzel</TableCell>
                      <TableCell>Budget</TableCell>
                      <TableCell>Bezeichnung</TableCell>

                      <TableCell>Ort</TableCell>
                      <TableCell>Bauherr</TableCell>
                      <TableCell>Aktionen</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredProjects.map((project) => (
                      <TableRowLink
                        key={project.id}
                        to={`/projects/${project.id}/dashboard`}
                        requiredPermissions={['ProjektOeffnen']}
                        projectId={project.id}
                      >
                        <TableCell>{project.kurzbez}</TableCell>
                        <TableCell>{project.budget}</TableCell>
                        <TableCell>{project.bez}</TableCell>
                        <TableCell>{project.ort}</TableCell>
                        <TableCell>{project.bauherr}</TableCell>
                        <TableCell align="right">
                          <Guarded
                            requiredPermissions={['ProjektOeffnen']}
                            projectId={project.id}
                            elseLock
                          >
                            <IconButton>
                              <ArrowForwardIcon fontSize="small" />
                            </IconButton>
                          </Guarded>
                        </TableCell>
                      </TableRowLink>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        )}
      </FilterBar>
    </>
  );
};

export default ProjectListTable;
