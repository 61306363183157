import { Checkbox, FormControlLabel, Switch } from '@mui/material';
import _ from 'lodash';
import React from 'react';

export type BooleanInputProps = {
  value: boolean;
  label?: string;
  toggle?: boolean;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  handleChange: (value: boolean) => void;
  limitRerender?: boolean;
};

const BooleanInput = ({
  value,
  label,
  handleChange,
  toggle,
  labelPlacement,
  limitRerender = false,
}: BooleanInputProps) => (
  <FormControlLabel
    sx={{ marginLeft: labelPlacement === 'start' ? '0px' : undefined }}
    labelPlacement={labelPlacement}
    control={
      toggle ? (
        <Switch
          checked={value}
          onChange={(e) => handleChange(e.target.checked)}
        />
      ) : (
        <Checkbox
          checked={value}
          onChange={(e) => handleChange(e.target.checked)}
        />
      )
    }
    label={label || ''}
  />
);
function propsAreEqual(
  prevProps: BooleanInputProps,
  nextProps: BooleanInputProps
) {
  if (nextProps.limitRerender) {
    return prevProps.value === nextProps.value;
  }
  return _.isEqual(prevProps, nextProps);
}

export default React.memo(BooleanInput, propsAreEqual);
