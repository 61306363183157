import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import { TextField, Box } from '@mui/material';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

const ExampleAnalysisForm = () => {
  const formik = useFormik({
    initialValues: {
      password: 'foobar',
      email1: '',
      email2: '',
      email3: '',
      email4: '',
      email5: '',
      email6: '',
      email7: '',
      email8: '',
      email9: '',
      email10: '',
      email11: '',
      email12: '',
      email13: '',
      email14: '',
      email15: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <div>
      {/* {[...Array(14)].map((value, index) => (
        <Box sx={{ display: 'grid' }}>
          <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        </Box>
      ))} */}

      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'grid', gap: 2 }}>
          <TextField
            fullWidth
            id="email1"
            name="email1"
            label={`Email 1`}
            value={formik.values.email1}
            onChange={formik.handleChange}
            error={formik.touched.email1 && Boolean(formik.errors.email1)}
            helperText={formik.touched.email1 && formik.errors.email1}
          />

          <TextField
            fullWidth
            id="email2"
            name="email2"
            label={`Email 2`}
            value={formik.values.email2}
            onChange={formik.handleChange}
            error={formik.touched.email2 && Boolean(formik.errors.email2)}
            helperText={formik.touched.email2 && formik.errors.email2}
          />

          <TextField
            fullWidth
            id="email3"
            name="email3"
            label={`Email 3`}
            value={formik.values.email3}
            onChange={formik.handleChange}
            error={formik.touched.email3 && Boolean(formik.errors.email3)}
            helperText={formik.touched.email3 && formik.errors.email3}
          />

          <TextField
            fullWidth
            id="email4"
            name="email4"
            label={`Email 4`}
            value={formik.values.email4}
            onChange={formik.handleChange}
            error={formik.touched.email4 && Boolean(formik.errors.email4)}
            helperText={formik.touched.email4 && formik.errors.email4}
          />

          <TextField
            fullWidth
            id="email5"
            name="email5"
            label={`Email 5`}
            value={formik.values.email5}
            onChange={formik.handleChange}
            error={formik.touched.email5 && Boolean(formik.errors.email5)}
            helperText={formik.touched.email5 && formik.errors.email5}
          />

          <TextField
            fullWidth
            id="email6"
            name="email6"
            label={`Email 6`}
            value={formik.values.email6}
            onChange={formik.handleChange}
            error={formik.touched.email6 && Boolean(formik.errors.email6)}
            helperText={formik.touched.email6 && formik.errors.email6}
          />

          <TextField
            fullWidth
            id="email7"
            name="email7"
            label={`Email 7`}
            value={formik.values.email7}
            onChange={formik.handleChange}
            error={formik.touched.email7 && Boolean(formik.errors.email7)}
            helperText={formik.touched.email7 && formik.errors.email7}
          />

          <TextField
            fullWidth
            id="email8"
            name="email8"
            label={`Email 8`}
            value={formik.values.email8}
            onChange={formik.handleChange}
            error={formik.touched.email8 && Boolean(formik.errors.email8)}
            helperText={formik.touched.email8 && formik.errors.email8}
          />

          <TextField
            fullWidth
            id="email9"
            name="email9"
            label={`Email 9`}
            value={formik.values.email9}
            onChange={formik.handleChange}
            error={formik.touched.email9 && Boolean(formik.errors.email9)}
            helperText={formik.touched.email9 && formik.errors.email9}
          />

          <TextField
            fullWidth
            id="email10"
            name="email10"
            label={`Email 10`}
            value={formik.values.email10}
            onChange={formik.handleChange}
            error={formik.touched.email10 && Boolean(formik.errors.email10)}
            helperText={formik.touched.email10 && formik.errors.email10}
          />

          <TextField
            fullWidth
            id="email11"
            name="email11"
            label={`Email 11`}
            value={formik.values.email11}
            onChange={formik.handleChange}
            error={formik.touched.email11 && Boolean(formik.errors.email11)}
            helperText={formik.touched.email11 && formik.errors.email11}
          />

          <TextField
            fullWidth
            id="email12"
            name="email12"
            label={`Email 12`}
            value={formik.values.email12}
            onChange={formik.handleChange}
            error={formik.touched.email12 && Boolean(formik.errors.email12)}
            helperText={formik.touched.email12 && formik.errors.email12}
          />

          <TextField
            fullWidth
            id="email13"
            name="email13"
            label={`Email 13`}
            value={formik.values.email13}
            onChange={formik.handleChange}
            error={formik.touched.email13 && Boolean(formik.errors.email13)}
            helperText={formik.touched.email13 && formik.errors.email13}
          />

          <TextField
            fullWidth
            id="email14"
            name="email14"
            label={`Email 14`}
            value={formik.values.email14}
            onChange={formik.handleChange}
            error={formik.touched.email14 && Boolean(formik.errors.email14)}
            helperText={formik.touched.email14 && formik.errors.email14}
          />

          <TextField
            fullWidth
            id="email15"
            name="email15"
            label={`Email 15`}
            value={formik.values.email15}
            onChange={formik.handleChange}
            error={formik.touched.email15 && Boolean(formik.errors.email15)}
            helperText={formik.touched.email15 && formik.errors.email15}
          />

          <Button color="primary" variant="contained" fullWidth type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default ExampleAnalysisForm;
