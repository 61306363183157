import { useMutation } from '@apollo/client';
import { Alert, Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { Form as FormikForm, Formik } from 'formik';
import { DocumentNode } from 'graphql';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import BlockNavigationDialog from '../BlockNavigationDialog';
import { useAccordionContext } from './AccordionForm';
import { useAccordionItemContext } from './AccordionItem';

export interface FormProps<TInput, TVariables> {
  shouldBlockNavigationWhenDirty?: boolean;
  mutation: DocumentNode;
  initialValues: TInput;
  makeVars: (values: TInput) => TVariables;
  refetch: () => void;
  validationSchema?: any | (() => any);
  header?: React.FC<{}>;
  wrapForm?: React.FC<{}>;
  wrapBody?: React.FC<{}>;
  wrapAction?: React.FC<{}>;
  onClose?: () => void;
}

const DefaultWrapBody: React.FC<{}> = ({ children }) => (
  <Grid container spacing={5}>
    {children}
  </Grid>
);
const DefaultWrapForm: React.FC<{}> = ({ children }) => (
  <Grid item xs={12}>
    {children}
  </Grid>
);
const DefaultWrapAction: React.FC<{}> = ({ children }) => (
  <Grid item xs={12}>
    {children}
  </Grid>
);

function Form<TInput, TData, TVariables>({
  children,
  shouldBlockNavigationWhenDirty = true,
  initialValues,
  makeVars,
  mutation,
  refetch,
  validationSchema,
  header: Header,
  wrapForm: WrapForm = DefaultWrapForm,
  wrapBody: WrapBody = DefaultWrapBody,
  wrapAction: WrapAction = DefaultWrapAction,
  onClose,
}: React.PropsWithChildren<FormProps<TInput, TVariables>>) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { setHasDirtyFields, hasDirtyFields } = useAccordionItemContext();
  const { setEditItemId } = useAccordionContext();

  const [handleMutation, { error }] = useMutation<TData, TVariables>(mutation);

  const handleClose = () => {
    if (setEditItemId) {
      setEditItemId(false);
    }
    if (setHasDirtyFields) {
      setHasDirtyFields(false);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleSubmit = async (value: TInput) => {
    const vars = makeVars(value);
    try {
      await handleMutation({ variables: vars });
      handleClose();
      enqueueSnackbar('Erfolgreich gespeichert.', { variant: 'success' });
      refetch();
    } catch (ex) {
      enqueueSnackbar('Das Formular konnte nicht gespeichert werden.', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <BlockNavigationDialog
        when={shouldBlockNavigationWhenDirty && hasDirtyFields}
        navigate={(path) => history.push(path)}
        shouldBlockNavigation={(location) => true}
      />
      {Header && Header}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(props) => {
          setHasDirtyFields(props.dirty);

          return (
            <FormikForm>
              <WrapBody>
                <WrapForm>
                  <Grid container spacing={3}>
                    {typeof children == 'function' ? children(props) : children}
                    {error && (
                      <Grid item xs={12}>
                        <Alert variant="outlined" severity="error">
                          {error.message}
                        </Alert>
                      </Grid>
                    )}
                  </Grid>
                </WrapForm>
                <WrapAction>
                  <Box display="flex" flexDirection="row-reverse">
                    <Box>
                      <Button
                        color="primary"
                        disabled={props.isSubmitting || !props.dirty}
                        fullWidth
                        size="large"
                        onClick={() => {
                          props.handleSubmit();
                        }}
                        variant="contained"
                      >
                        Speichern
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        color="primary"
                        disabled={props.isSubmitting}
                        fullWidth
                        size="large"
                        onClick={() => {
                          props.resetForm();
                          handleClose();
                        }}
                      >
                        Abbrechen
                      </Button>
                    </Box>
                  </Box>
                </WrapAction>
              </WrapBody>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
}

export default Form;
