import { FC } from 'react';
import { useState } from 'react';
import Chart from 'react-apexcharts';
import { PieChartDataType } from '../../domain/widgets/ExamplePieChartWidget';
interface BarChartProps {
  height: number;
  data: PieChartDataType;
}

const PieChart: FC<BarChartProps> = ({ height, data }) => {
  const getHeight = () => {
    return height * 100 - 52;
  };

  let dataFormatted = {
    options: {
      labels: data.labels,
    },
    series: data.series,
  };

  const [state] = useState(dataFormatted);

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="pie"
      height={getHeight()}
    />
  );
};

export default PieChart;
