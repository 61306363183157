import { useState, useEffect, FC } from 'react';
import { CommonWidgetProps } from './Widget';
import PieChart from '../../common/charts/PieChart';

export interface PieChartDataType {
  labels: string[];
  series: number[];
}

export const getPieChartData = (): Promise<PieChartDataType> => {
  return Promise.resolve({
    labels: ['A', 'B', 'C', 'D', 'E'],
    series: [44, 55, 41, 17, 15],
  });
};

const ExamplePiChartWidget: FC<CommonWidgetProps> = ({ height }) => {
  const [chartData, setChartData] = useState<PieChartDataType>();

  useEffect(() => {
    getPieChartData().then((response) => {
      setChartData(response);
    });
  }, []);

  return <>{chartData && <PieChart height={height} data={chartData} />}</>;
};

export default ExamplePiChartWidget;
