import { TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';

export interface TextInputProps {
  handleChange: (value: string) => void;
  handleBlur?: () => void;
  value: string | null;
  error?: string;
  label?: string;
  multiline?: boolean;
  limitRerender?: boolean;
}

const TextInput = ({
  handleChange,
  handleBlur,
  multiline = false,
  label,
  value,
  error,
  limitRerender = false,
}: TextInputProps) => {
  return (
    <TextField
      value={value || ''}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={handleBlur}
      label={label}
      multiline={multiline}
      rows={multiline ? 3 : undefined}
      error={!!error}
      helperText={error}
    />
  );
};

function propsAreEqual(prevProps: TextInputProps, nextProps: TextInputProps) {
  if (nextProps.limitRerender) {
    return (
      prevProps.value === nextProps.value && prevProps.error === nextProps.error
    );
  }
  return _.isEqual(prevProps, nextProps);
}

export default React.memo(TextInput, propsAreEqual);
