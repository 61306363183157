import { Grid, GridSize, Typography } from '@mui/material';
import { FC } from 'react';

interface FormFieldProps {
  label: string;
  xs?: boolean | GridSize;
  xl?: boolean | GridSize;
}

const FormFieldDisplay: FC<FormFieldProps> = ({
  children,
  label,
  xs = 12,
  xl = 12,
}) => (
  <Grid item xs={xs} xl={xl}>
    <Grid container alignItems="center">
      <Grid item xs={6}></Grid>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 11 }}>{label}</Typography>
        {children}
      </Grid>
    </Grid>
  </Grid>
);

export default FormFieldDisplay;
