import React, { useState } from 'react';
import { DesktopDatePicker } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { format, isValid, parseISO } from 'date-fns';
import { Box } from '@mui/system';
import _ from 'lodash';

export interface DateInputProps {
  label?: string;
  value?: string | null;
  onChange?: (input: string, pickerDate?: Date | null) => void;
  onBlur?: (input: string) => void;
  error?: string;
  limitRerender?: boolean;
  autoFocus?: boolean;
}

const currentMonth = new Date().toLocaleString('de-DE', {
  month: '2-digit',
});

const currentYear = new Date().toLocaleString('de-DE', {
  year: 'numeric',
});

const DateInput = ({
  label,
  value,
  onChange,
  onBlur,
  error,
  limitRerender = false,
  autoFocus = false,
}: DateInputProps) => {
  const [focused, setFocused] = useState(autoFocus);
  const [open, setOpen] = useState(false);

  const formatDate = (input: string) => {
    const [dayInput, monthInput, yearInput] = input.split('.');
    const day = dayInput && dayInput.length === 1 ? '0' + dayInput : dayInput;
    const month =
      monthInput && monthInput.length === 1
        ? '0' + monthInput
        : monthInput || currentMonth;
    const year = yearInput
      ? currentYear.slice(0, 4 - yearInput.length) + yearInput
      : currentYear;
    const formattedDate = `${year}-${month}-${day}T00:00:00`;
    return formattedDate;
  };

  const handleChange = (date: Date | null, input: string | undefined) => {
    if (!input && !date) {
      if (onChange) onChange('');
    } else if (!input && date) {
      date?.setHours(0, 0, 0, 0);
      const isoString = date ? format(date, "yyyy-MM-dd'T'HH:mm:ss") : '';
      if (onChange) onChange(isoString, date);
    } else if (input && date) {
      if (onChange) onChange(input);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      if (onBlur) onBlur('');
    } else {
      const formattedDate = formatDate(event.target.value);
      const dateObj = parseISO(formattedDate);
      if (!isValid(dateObj)) {
        if (onBlur) onBlur('Invalid Date');
      } else {
        if (onBlur) onBlur(formattedDate);
      }
    }
    setFocused(false);
  };

  const customToolbar = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      m={2}
      mb={0}
    >
      <Button onClick={() => selectTodayHandler()}>Heute</Button>
      <Button onClick={() => clearHandler()}>Zurücksetzen</Button>
    </Box>
  );

  const selectTodayHandler = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let todayFormatted = format(today, "yyyy-MM-dd'T'HH:mm:ss");
    if (onChange) onChange(todayFormatted, today);
    setOpen(false);
  };

  const clearHandler = () => {
    if (onChange) onChange('', null);
    setOpen(false);
  };
  return (
    <>
      <DesktopDatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        value={value || null}
        onChange={handleChange}
        mask="__.__.____"
        showToolbar
        ToolbarComponent={() => customToolbar}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            autoFocus={autoFocus}
            error={!!error}
            helperText={error}
            label={label}
            InputLabelProps={{
              // Workaround for issue with label transition showing at initialisation
              shrink: focused || value || value === '' ? true : false,
            }}
            onFocus={() => setFocused(true)}
            onBlur={handleBlur}
            inputProps={{
              ...params.inputProps,
              placeholder: 'TT.MM.JJJJ',
            }}
          />
        )}
      />
    </>
  );
};

function dateInputPropsAreEqual(
  prevProps: DateInputProps,
  nextProps: DateInputProps
) {
  if (nextProps.limitRerender) {
    return (
      prevProps.value === nextProps.value && prevProps.error === nextProps.error
    );
  }
  return _.isEqual(prevProps, nextProps);
}

export default React.memo(DateInput, dateInputPropsAreEqual);
