import {
  Box,
  Button,
  ClickAwayListener,
  Popper,
  Typography,
} from '@mui/material';
import {
  createElement,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ColumnFilterWithKey, FilterBarContext } from './FilterBar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

interface FilterMenuButtonProps {
  filter: ColumnFilterWithKey;
}

const FilterMenuButton = ({ filter }: FilterMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [shouldApplyWithNextRender, setShouldApplyWithNextRender] =
    useState(false);
  const filterContext = useContext(FilterBarContext);
  const open = Boolean(anchorEl);
  const buttonRef = createRef<HTMLButtonElement>();
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (open) {
        filterContext.reset();
        setAnchorEl(null);
      } else setAnchorEl(event.currentTarget);
    },
    [filterContext, open]
  );

  const handleClose = useCallback(() => {
    filterContext.reset();
    setAnchorEl(null);
  }, [filterContext]);

  const onSave = useCallback(() => {
    console.log('onsave', filterContext.filterState);
    filterContext.applyFilter();
    setAnchorEl(null);
  }, [filterContext]);

  useEffect(() => {
    if (shouldApplyWithNextRender) {
      onSave();
      setShouldApplyWithNextRender(false);
    }
  }, [shouldApplyWithNextRender, onSave]);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        buttonRef.current?.focus();
        setShouldApplyWithNextRender(true);
      }
    },
    [buttonRef]
  );

  return (
    <Box marginRight={1} height="100%">
      <Button
        disableElevation
        onClick={handleClick}
        color="secondary"
        variant="outlined"
        sx={{
          height: '100%',
          color: 'gray',
          fontStyle: 'normal',
          borderColor: 'lightgrey',
          bgcolor: 'white',
          paddingRight: 0,

          ...(open && {
            borderColor: 'secondary.main',
            zIndex: 1301,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderBottom: 0,
            borderBottomColor: 'white',
            paddingBottom: 2,
          }),

          '&:hover': {
            background: 'white',
            ...(open && { borderBottom: 0, borderBottomColor: 'white' }),
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography>{filter.heading}</Typography>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Box>
      </Button>
      <Popper anchorEl={anchorEl} open={open} placement="bottom-start">
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseUp">
          <Box
            onKeyPress={handleKeyPress}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: 'white',
              marginTop: '-1px',
              border: '1px',
              borderRadius: 1,
              borderStyle: 'solid',
              borderTopLeftRadius: 0,
              borderColor: 'lightgrey',
              minWidth: 400,
              ...(open && {
                borderColor: 'secondary.main',
              }),
            }}
          >
            <Box flexGrow={1}>
              {createElement(filter.filterComponent, {
                value: filterContext.filterState[filter.id]?.state,
                propertyName: filter.name,
                heading: filter.heading,
                id: filter.id,
                autoFocus: true,
              })}
            </Box>

            <Box
              sx={{
                paddingX: 2,
                paddingY: 1,
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Button
                ref={buttonRef}
                variant="contained"
                color="primary"
                onClick={() => onSave()}
              >
                Anwenden
              </Button>
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

export default FilterMenuButton;
