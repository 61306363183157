import { Box } from '@mui/material';
import React, { useContext, useCallback } from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { FilterBarContext, FilterInputProps } from './FilterBar';
import DecimalInput from '../../inputs/DecimalInput';

export const RangeFilter = ({ ...props }: FilterInputProps) => {
  const filterContext = useContext(FilterBarContext);
  const state = filterContext.filterState[props.id]?.state;
  const setFilter = useCallback(
    (values: { min?: number | undefined; max?: number | undefined }) => {
      if (values.min || values.max) {
        filterContext.setFilter({
          filterFunction: 'BETWEEN',
          state: { ...values },
          filterText:
            props.heading +
            ': ' +
            (values.max ? '' : 'ab ') +
            (values.min || 0) +
            '€' +
            (values.max ? ' - ' + values.max + '€' : ''),
          propertyName: props.propertyName,
          id: props.id,
        });
      } else {
        filterContext.deleteFilter(props.id);
      }
    },
    [filterContext, props.id, props.heading, props.propertyName]
  );

  return (
    <>
      <Box sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
        <Box flexGrow={1}>
          <DecimalInput
            autoFocus={props.autoFocus && !state?.min}
            label={'Min'}
            value={state?.min || null}
            handleChange={(value) => {
              setFilter({ min: value.floatValue, max: state?.max });
            }}
          />
        </Box>
        <RemoveIcon />
        <Box flexGrow={1}>
          <DecimalInput
            autoFocus={props.autoFocus && !!state?.min && !state?.max}
            label={'Max'}
            value={state?.max || null}
            handleChange={(value) => {
              setFilter({ min: state?.min, max: value.floatValue });
            }}
          />
        </Box>
      </Box>
    </>
  );
};
