import { TokenData, User } from '../types/user';
import { Locale } from '../types/locale';

class AuthApi {
  async login({ email, password }: any): Promise<TokenData> {
    const data = new URLSearchParams();
    data.append('username', email);
    data.append('password', password);

    const requestOptions = {
      method: 'post',
      body: data,
    };
    const response = await fetch(
      `${process.env.REACT_APP_REST_API}/token`,
      requestOptions
    );
    if (!response.ok) {
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    return response.json();
  }

  async me(accessToken: any): Promise<User> {
    try {
      const requestOptions = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_REST_API}/users/me/`,
        requestOptions
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const user = await response.json();

      return {
        id: user.login_name,
        email: user.email,
        name: user.anzeige,
      };
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('Internal server error');
    }
  }

  async locale(accessToken: any): Promise<Locale> {
    try {
      const requestOptions = {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await fetch(
        `${process.env.REACT_APP_REST_API}/locale/`,
        requestOptions
      );
      if (!response.ok) {
        const message = `An error has occured: ${response.status}`;
        throw new Error(message);
      }
      const locale: Locale = await response.json();

      return {
        de: locale.de,
      };
    } catch (err) {
      console.error('[Auth Api]: ', err);
      throw new Error('Internal server error');
    }
  }
}

export const authApi = new AuthApi();
