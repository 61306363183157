import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Toolbar, styled, Typography } from '@mui/material';
import type { AppBarProps } from '@mui/material';
import AccountPopover from '../../domain/dashboard/AccountPopover';
import useProject from '../../hooks/useProject';

interface NavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const NavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none',
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

const Navbar: FC<NavbarProps> = (props) => {
  const project = useProject();

  return (
    <NavbarRoot {...props}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Box
          component={RouterLink}
          to="/"
          display="flex"
          flexDirection="row"
          justifyContent="center"
        >
          <img src="/logo.png" height="24" width="24" alt="Logo"></img>
        </Box>

        <Typography variant="h6" marginLeft={2}>
          {project && project.bez ? 'Projekt: ' + project.bez : 'QS-CostCo'}
        </Typography>

        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </NavbarRoot>
  );
};

export default Navbar;
