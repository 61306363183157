import { TextField } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';

export type DecimalInputProps = {
  value: string | number | null | undefined;
  handleChange: (value: NumberFormatValues) => void;
  handleBlur?: () => void;
  label?: string;
  error?: string;
  showThousandSeperator?: boolean;
  endAdornment?: JSX.Element;
  limitRerender?: boolean;
  autoFocus?: boolean;
};

const DecimalInput = ({
  value,
  handleChange,
  handleBlur,
  isNumericString = true,
  decimalScale = 2,
  decimalSeparator = ',',
  showThousandSeperator = false,
  fixedDecimalScale = true,
  limitRerender = false,
  error,
  endAdornment,
  label,
  autoFocus = false,
}: DecimalInputProps & NumberFormatProps) => {
  const handleValueChange = (value: NumberFormatValues) => {
    handleChange(value);
  };
  return (
    <>
      <NumberFormat
        value={value}
        error={!!error}
        isNumericString={isNumericString}
        decimalScale={decimalScale}
        decimalSeparator={decimalSeparator}
        thousandSeparator={showThousandSeperator ? '.' : false}
        fixedDecimalScale={fixedDecimalScale}
        onValueChange={handleValueChange}
        onBlur={handleBlur}
        customInput={TextField}
        InputProps={{
          endAdornment: endAdornment ? endAdornment : undefined,
        }}
        style={{ width: '100%' }}
        inputProps={{
          style: {
            padding: '8.5px 14px',
          },
        }}
        variant="outlined"
        helperText={error}
        placeholder={label}
        autoFocus={autoFocus}
      />
    </>
  );
};

function propsAreEqual(
  prevProps: DecimalInputProps,
  nextProps: DecimalInputProps
) {
  if (nextProps.limitRerender) {
    return (
      prevProps.value === nextProps.value && prevProps.error === nextProps.error
    );
  }
  return _.isEqual(prevProps, nextProps);
}

export default React.memo(DecimalInput, propsAreEqual);
