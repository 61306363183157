import { TableRow } from '@mui/material';
import { useHistory } from 'react-router';
import { Guardable } from '../../contexts/AuthorizationContext';
import Guarded from '../auth/Guarded';

type TableRowLinkProps = {
  to: string;
} & Guardable;

const TableRowLink: React.FC<TableRowLinkProps> = ({
  children,
  to,
  requiredPermissions,
  projectId,
}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(to);
  };

  return (
    <Guarded
      requiredPermissions={requiredPermissions || []}
      projectId={projectId}
      elseRender={<TableRow>{children}</TableRow>}
    >
      <TableRow hover onClick={handleClick} sx={{ cursor: 'pointer' }}>
        {children}
      </TableRow>
    </Guarded>
  );
};

export default TableRowLink;
