import { useField } from 'formik';
import { useCallback } from 'react';
import { NumberFormatValues } from 'react-number-format';
import FormField from '../form/FormField';
import { FormGridProps } from '../form/FormGridProps';
import CurrencyInput from './CurrencyInput';

interface FormCurrencyInputProps {
  name: string;
  label?: string;
  currency?: string;
  decimalScale?: number;
  showThousandSeperator?: boolean;
}

const FormCurrencyInput = ({
  currency = '€',
  decimalScale,
  showThousandSeperator,
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: FormCurrencyInputProps & FormGridProps) => {
  const [field, meta, form] = useField<string | null>(props.name);
  const handleChange = useCallback(
    (value: NumberFormatValues) => {
      form.setValue(value.value || null);
    },
    [form]
  );
  const handleBlur = useCallback(() => {
    form.setTouched(true);
  }, [form]);
  return (
    <FormField xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <CurrencyInput
        label={props.label}
        value={field.value}
        handleChange={handleChange}
        handleBlur={handleBlur}
        decimalScale={decimalScale}
        showThousandSeperator={showThousandSeperator}
        error={meta.touched && meta.error ? meta.error : undefined}
        currency="€"
        limitRerender
      />
    </FormField>
  );
};

export default FormCurrencyInput;
