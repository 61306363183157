import { InputAdornment, TextField } from '@mui/material';
import { useContext } from 'react';
import { FilterBarContext } from './FilterBar';
import SearchIcon from '@mui/icons-material/Search';
const SearchFilter = () => {
  const filterBarContext = useContext(FilterBarContext);
  return (
    <TextField
      value={filterBarContext.searchValue || ''}
      onChange={(e) => filterBarContext.setSearchValue(e.target.value)}
      sx={{ marginBottom: 0, backgroundColor: 'white' }}
      InputProps={{
        sx: { height: 36, padding: 0 },

        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
      }}
    />
  );
};
export default SearchFilter;
