import { NumberFormatValues } from 'react-number-format';
import DecimalInput from './DecimalInput';

export type CurrencyInputProps = {
  handleChange: (value: NumberFormatValues) => void;
  handleBlur?: () => void;
  value: string | null;
  label?: string;
  error?: string;
  currency?: string;
  decimalScale?: number;
  showThousandSeperator?: boolean;
  limitRerender?: boolean;
};

const CurrencyInput = ({
  label,
  currency = '€',
  decimalScale = 2,
  showThousandSeperator = true,
  value,
  error,
  handleChange,
  handleBlur,
  ...props
}: CurrencyInputProps) => {
  return (
    <DecimalInput
      label={label}
      value={value}
      handleChange={handleChange}
      handleBlur={handleBlur}
      decimalScale={decimalScale}
      error={error}
      endAdornment={<span>{currency}</span>}
      showThousandSeperator={showThousandSeperator}
      limitRerender
    />
  );
};

export default CurrencyInput;
