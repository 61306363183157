import { Typography } from '@mui/material';
import Page from '../common/layout/Page';

const NoPermission = () => (
  <Page
    name="Fehlende Berechtigung"
    title="Fehlende Berechtigung"
    breadcrumbItems={[]}
  >
    <Typography variant="body1">
      Ihnen fehlt die benötigte Berechtigung.
    </Typography>
  </Page>
);

export default NoPermission;
