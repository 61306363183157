import { FC } from 'react';
import Page from '../common/layout/Page';
import ProjectListTable from '../domain/projects/ProjectListTable';

const Projects: FC = () => {
  return (
    <>
      <Page name="Projektauswahl" title="Projektauswahl">
        <ProjectListTable />
      </Page>
    </>
  );
};

export default Projects;
