import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Breadcrumbs } from '@mui/material';
import BreadcrumbItem, { BreadcrumbItemProps } from './BreadcrumbItem';

export interface BreadcrumbProps {
  items?: BreadcrumbItemProps[];
}

const Breadcrumb = ({ items }: BreadcrumbProps) => {
  if (!items) return <></>;

  return (
    <Breadcrumbs
      aria-label="Seitennavigation"
      separator={<ChevronRightIcon fontSize="small" />}
      sx={{ mt: 1, alignItems: 'baseline' }}
    >
      {items.map(({ to, name }, index) => (
        <BreadcrumbItem key={index} to={to} name={name} />
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
