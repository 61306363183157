import DecimalDisplay from './DecimalDisplay';

interface CurrenyDisplayProps {
  value?: number | string | null;
}

const CurrencyDisplay = ({ value }: CurrenyDisplayProps) => (
  <DecimalDisplay
    currency="EUR"
    numberStyle="currency"
    value={value}
    minimumFractionDigits={2}
    maximumFractionDigits={2}
  />
);

export default CurrencyDisplay;
