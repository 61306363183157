import { useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import {
  Card,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { Order } from '../../schema/Order';
import TableRowLink from '../../common/table/TableRowLink';

interface OrderListTableProps {
  orders: Order[];
}

const applyPagination = (
  orders: Order[],
  page: number,
  limit: number
): Order[] => orders.slice(page * limit, page * limit + limit);

const OrderListTable: FC<OrderListTableProps> = (props) => {
  const { orders, ...other } = props;
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(5);

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setPage(newPage);
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
  };

  const paginatedOrders = applyPagination(orders, page, limit);

  return (
    <Card {...other}>
      <TableContainer>
        <Table sx={{ minWidth: 1150 }}>
          <TableHead>
            <TableRow>
              <TableCell>Auftragsnummer</TableCell>
              <TableCell>Auftragnehmer</TableCell>
              <TableCell>Leistung</TableCell>
              <TableCell>Auftragssumme</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedOrders.map((order) => (
              <TableRowLink
                to={`/projects/${order.projektId}/orders/${order.id}`}
                key={order.id}
              >
                <TableCell>
                  <Link
                    color="textPrimary"
                    component={RouterLink}
                    to="/dashboard/orders/1"
                    underline="none"
                    variant="subtitle2"
                  >
                    {order.auftragsnummer}
                  </Link>
                </TableCell>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    {order.auftragnehmer}
                  </Typography>
                </TableCell>
                <TableCell>{order.leistungsbez}</TableCell>
                <TableCell>
                  {order.auftragssumme
                    ? `${parseFloat(order.auftragssumme).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}€`
                    : '-'}
                </TableCell>
                <TableCell>
                  <Typography color="textSecondary" variant="body2">
                    {order.auftragsdatum
                      ? format(parseISO(order.auftragsdatum), 'dd.MM.yyyy')
                      : '-'}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <IconButton>
                    <ArrowRightIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRowLink>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={orders.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

export default OrderListTable;
