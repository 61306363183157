import RGL, { Responsive, WidthProvider } from 'react-grid-layout';
import { useState } from 'react';
import { Box, IconButton } from '@mui/material';

import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { SelectChangeEvent } from '@mui/material/Select';
import './styles.css';
// import '../../../node_modules/react-grid-layout/css/styles.css';
// import '../../../node_modules/react-resizable/css/styles.css';

import Widget from '../widgets/Widget';
import AddDashboardWidgets from './AddDashboardWidgets';

const ReactGridLayout = WidthProvider(Responsive);

const getId = () => {
  let idCounter = Math.floor(Math.random() * 10000);
  return idCounter.toString();
};

const LOCAL_STORAGE_LAYOUT_KEY = 'gridLayout';

const DEFAULT_STATE: { layout: RGL.Layout[] } = {
  layout: [{ x: 0, y: 0, w: 12, h: 3, i: '1', minH: 3 }],
};

const DashboardGridLayout = () => {
  const getLayoutFromLocalStorage = localStorage.getItem(
    LOCAL_STORAGE_LAYOUT_KEY
  );
  let layout = JSON.parse(getLayoutFromLocalStorage as string);
  let layoutFormatted: typeof DEFAULT_STATE = {
    layout,
  };

  if (layoutFormatted.layout === null)
    layoutFormatted = {
      ...DEFAULT_STATE,
    };

  const [state, setState] = useState(layoutFormatted);
  const [widgetsDropdown, setWidgetsDropdown] = useState('');

  const showComponent = (id: string) => {
    let widget = state.layout.find((item) => item.i === id);
    let height = widget?.h;

    if (!height) {
      return;
    }
    return <Widget id={id} height={height} />;
  };

  const addNewWidget = (
    x?: number,
    y?: number,
    w?: number,
    h?: number,
    id?: string,
    minH?: number
  ) => {
    const { layout } = state;
    const newItem = {
      x: x ? x : 0,
      y: y ? y : 0,
      w: w ? w : 3,
      h: h ? h : 2,
      i: id ? id : getId(),
      minH: minH,
    };

    if (layout.some((item) => item.x === 0 && item.y === 0)) {
      setState({
        layout: layout
          .map((item) => {
            if (item.x === 0) {
              return { ...item };
            }

            return item;
          })
          .concat([newItem]),
      });
    } else {
      setState({ layout: layout.concat([newItem]) });
    }
  };

  const deleteItem = (id: string) => {
    let layout = state.layout.filter((item) => {
      return item.i !== id;
    });

    setState({ layout });
  };

  const onLayoutChange = (layout: RGL.Layout[]) => {
    setState({ layout });
    localStorage.setItem(LOCAL_STORAGE_LAYOUT_KEY, JSON.stringify(layout));
  };

  const handleDropdownChange = (event: SelectChangeEvent) => {
    const dropdownID = event.target.value as string;
    setWidgetsDropdown(dropdownID);

    const widgetExists = state.layout.findIndex(
      (item) => item.i === dropdownID
    );
    if (widgetExists !== -1) {
      alert('widget already exists');
      return;
    }

    addNewWidget(0, 0, 12, 3, dropdownID, 3);
  };

  return (
    <>
      <AddDashboardWidgets
        widgetsDropdown={widgetsDropdown}
        handleDropdownChange={handleDropdownChange}
        addNewWidget={addNewWidget}
      />
      <ReactGridLayout
        isDraggable={true}
        isResizable={true}
        rowHeight={100}
        preventCollision={false}
        onLayoutChange={(layout) => onLayoutChange(layout)}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 12, xs: 12, xxs: 12 }}
      >
        {state.layout.map((item) => (
          <div key={item.i} data-grid={item}>
            <Box>
              <Box display="flex" flexDirection="row-reverse">
                <IconButton
                  aria-label="delete"
                  onClick={() => deleteItem(item.i)}
                  style={{ padding: '0px', marginBottom: '8px' }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
              {showComponent(item.i)}
            </Box>
          </div>
        ))}
      </ReactGridLayout>
    </>
  );
};

export default DashboardGridLayout;
