import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export interface BreadcrumbItemProps {
  to?: string;
  name: string;
}

const BreadcrumbItem = ({ to, name }: BreadcrumbItemProps) => {
  if (to)
    return (
      <Link
        underline="hover"
        color="textPrimary"
        component={RouterLink}
        to={to}
        variant="subtitle2"
        sx={{ margin: 0 }}
      >
        {name}
      </Link>
    );

  return (
    <Link color="textSecondary" variant="subtitle2" underline="none">
      {name}
    </Link>
  );
};

export default BreadcrumbItem;
