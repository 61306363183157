import type { FC, ReactNode } from 'react';
import { styled } from '@mui/material';
import Sidebar from '../navigation/Sidebar';
import Container from '@mui/material/Container';
import Navbar from '../navigation/Navbar';

interface LayoutProps {
  children?: ReactNode;
}

const LayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const LayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
}));

const LayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const LayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch',
});

const Layout: FC<LayoutProps> = ({ children }: LayoutProps) => {
  return (
    <LayoutRoot>
      <Navbar />
      <Sidebar />
      <LayoutWrapper>
        <LayoutContainer>
          <LayoutContent>
            <Container maxWidth={false}>{children}</Container>
          </LayoutContent>
        </LayoutContainer>
      </LayoutWrapper>
    </LayoutRoot>
  );
};

export default Layout;
