import { Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import React from 'react';
export interface Option {
  key: string;
  text: string;
}

export interface DropDownInputProps {
  handleChange: (value: string | string[] | null) => void;
  handleBlur?: () => void;
  error: string | undefined;
  items: Option[];
  value: string | string[] | null;
  label?: string;
  multiple?: boolean;
  optional?: boolean;
  limitRerender?: boolean;
}

const DropDownInput = ({
  value,
  handleChange,
  handleBlur,
  error,
  items,
  label,
  multiple,
  optional,
  limitRerender = false,
}: DropDownInputProps) => {
  const renderValue = () =>
    !multiple
      ? undefined
      : (selected: string | string[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {items
              .filter((item) => (selected as string[]).includes(item.key))
              .map((value) => (
                <Chip
                  variant="filled"
                  key={value.key}
                  label={value.text}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onDelete={() => {
                    handleChange(
                      (selected as string[]).filter((s) => s !== value.key)
                    );
                  }}
                />
              ))}
          </Box>
        );

  return (
    <FormControl size="small" fullWidth>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        fullWidth
        labelId={`${label}-label`}
        label={label}
        error={!!error}
        multiple={multiple}
        value={value || ''}
        onBlur={handleBlur}
        onChange={(e) => handleChange(e.target.value || null)}
        size="small"
        renderValue={renderValue()}
      >
        {!multiple && optional && (
          <MenuItem value="">
            <em>Leer</em>
          </MenuItem>
        )}
        {items.map(({ text, key }) => (
          <MenuItem key={key} value={key}>
            {text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
function propsAreEqual(
  prevProps: DropDownInputProps,
  nextProps: DropDownInputProps
) {
  if (nextProps.limitRerender) {
    return (
      JSON.stringify(prevProps.value) === JSON.stringify(nextProps.value) &&
      prevProps.error === nextProps.error
    );
  }
  return _.isEqual(prevProps, nextProps);
}

export default React.memo(DropDownInput, propsAreEqual);
