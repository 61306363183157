import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
} from '@mui/material';
import { createElement, useContext, useState } from 'react';
import { ColumnFilterWithKey, FilterBarContext } from './FilterBar';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterClearButton from './FilterClearButton';
interface FilterDialogProps {
  filters: ColumnFilterWithKey[];
}

const FilterDialog = ({ filters }: FilterDialogProps) => {
  const filterContext = useContext(FilterBarContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    filterContext.reset();
    setOpen(false);
  };

  const handleSave = () => {
    filterContext.applyFilter();
    setOpen(false);
  };

  const handleClose = () => {
    filterContext.reset();
    setOpen(false);
  };
  return (
    <Box>
      <Button
        onClick={handleClickOpen}
        color="secondary"
        variant="outlined"
        sx={{
          color: 'gray',
          fontStyle: 'normal',
          borderColor: 'lightgrey',
          bgcolor: 'white',
          paddingRight: 0.5,
          '&:hover': {
            background: 'white',
          },
        }}
      >
        <Typography>Alle Filter</Typography>
        <FilterAltIcon fontSize="small" />
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xl">
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            Filtereinstellungen
            <Typography>
              Anzahl Filterergebnisse:{' '}
              {filterContext.currentFilterResult.length}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {filters.map((filter, index) => (
            <div key={filter.id + '-dialog-' + index}>
              {filter.heading}:
              {createElement(filter.filterComponent, {
                propertyName: filter.name,
                heading: filter.heading,
                id: filter.id,
                value: filterContext.filterState[filter.id]?.state,
                autoFocus: index === 0 ? true : false,
              })}
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Box sx={{ display: 'flex', width: '100%' }}>
            <FilterClearButton />
            <Box flexGrow={1} />
            <Button onClick={handleCancel}>Abbrechen</Button>
            <Button onClick={handleSave}>Anwenden</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default FilterDialog;
