import { FormControl, Grid } from '@mui/material';
import { FC } from 'react';
import { FormGridProps } from './FormGridProps';

type FormFieldProps = {
  fullWidth?: boolean;
} & FormGridProps;

const FormField: FC<FormFieldProps> = ({
  children,
  xs = 12,
  fullWidth = true,
  ...gridProps
}) => (
  <Grid item xs={xs} {...gridProps}>
    <Grid container alignItems="center">
      <Grid item xs={12}>
        <FormControl fullWidth={fullWidth}>{children}</FormControl>
      </Grid>
    </Grid>
  </Grid>
);

export default FormField;
