import { FC, useState } from 'react';
import PivotTable from '../../common/data/PivotTable';
import Page from '../../common/layout/Page';
import kosten_data from '../../data.json';
import DateDisplay from '../../common/displays/DateDisplay';
import DecimalDisplay from '../../common/displays/DecimalDisplay';
import { FilterBar } from '../../common/data/filter/FilterBar';
import AdaptiveFilter from '../../common/data/filter/AdaptiveFilter';
import { Box } from '@mui/material';
import BooleanInput from '../../common/inputs/BooleanInput';
import useProject from '../../hooks/useProject';
import TextDisplay from '../../common/displays/TextDisplay';

const summe = (a: any, b: any): number => {
  return Number(a ?? 0) + Number(b ?? 0);
};

interface Kosten {
  DATUM: any | null;
  BETR_BUDGET: any | null;
  BETR_ENTNOMMEN: any | null;
  BETR_RESERVE: any | null;
  HAUPTKOSTENGRUPPE: any | null;
  KOSTENGRUPPE: any | null;
  GEWERK: any | null;
  TEILPROJEKT: any | null;
  FINANZIERUNG: any | null;
  ZUSTAENDIG: any | null;
  KOSTENART: string;
}

const TreeCurrencyDisply = (value: string) =>
  DecimalDisplay({
    currency: 'EUR',
    numberStyle: 'currency',
    value: value,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

const ProjectFormulartest: FC = () => {
  const [showControl, setShowControl] = useState(true);
  const project = useProject();
  return (
    <Page name="Kosten" title={`Formulartest`} breadcrumbItems={[]}>
      <div style={{ width: '100%' }}>
        <FilterBar
          data={kosten_data}
          filters={[
            {
              name: 'TEILPROJEKT',
              heading: 'Teilprojekt',
              filterComponent: AdaptiveFilter,
            },
          ]}
          customControl={() => (
            <Box>
              <BooleanInput
                labelPlacement="start"
                value={showControl}
                handleChange={setShowControl}
                label={'Einstellungen anzeigen:'}
                toggle
              />
            </Box>
          )}
          searchFields={['GEWERK', 'TEILPROJEKT', 'FINANZIERUNG']}
          id={'costsFilterBar' + project?.id}
        >
          {(filteredProjects) => (
            <PivotTable<Kosten>
              data={filteredProjects}
              groupFields={[
                { name: 'HAUPTKOSTENGRUPPE', color: 'primary' },
                { name: 'TEILPROJEKT' },
                { name: 'FINANZIERUNG' },
                { name: 'ZUSTAENDIG' },
              ]}
              groupHeading="Kostenelement"
              groupColumnSx={{ width: '15%' }}
              columns={[
                {
                  key: 'DATUM',
                  heading: 'Datum',
                  displayComponent: (value: string) =>
                    DateDisplay({ date: value }),
                  sx: { width: '15%' },
                },
                {
                  key: 'FINANZIERUNG',
                  heading: 'Finanzierung',
                  displayComponent: (value: string) =>
                    TextDisplay({ value: value }),
                  sx: { width: '15%' },
                },
                {
                  key: 'BETR_BUDGET',
                  heading: 'Summe von BETR_BUDGET',
                  aggregateFunction: summe,
                  displayComponent: TreeCurrencyDisply,
                  sx: { textAlign: 'right', width: '25%' },
                },
                {
                  key: 'BETR_ENTNOMMEN',
                  heading: 'Summe von BETR_ENTNOMMEN',
                  aggregateFunction: summe,
                  displayComponent: TreeCurrencyDisply,
                  sx: { textAlign: 'right', width: '25%' },
                },
                {
                  key: 'BETR_RESERVE',
                  heading: 'Summe von BETR_RESERVE',
                  aggregateFunction: summe,
                  displayComponent: TreeCurrencyDisply,
                  sx: { textAlign: 'right', width: '25%' },
                },
              ]}
              showControl={showControl}
              searchFields={['FINANZIERUNG']}
            />
          )}
        </FilterBar>
      </div>
    </Page>
  );
};

export default ProjectFormulartest;
