import TextDisplay from './TextDisplay';

interface DateDisplayProps {
  date: string;
}

const DateDisplay = ({ date }: DateDisplayProps) => {
  if (!date) return <></>;
  const formattedDate = new Date(date).toLocaleString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return <TextDisplay value={formattedDate} />;
};

export default DateDisplay;
