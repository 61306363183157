import { useApolloClient } from '@apollo/client';
import gql from 'graphql-tag';
import { createContext, FC, useEffect, useState } from 'react';
import { GetPermissions } from '../schema/GetPermissions';
import { Role } from '../schema/Role';

interface AuthorizationContextState {
  permissions: Permission[];
  roles: Role[];
  loading: boolean;
}

interface Permission {
  id: string;
  instances: string[];
  allowAll: boolean;
}

export type PermissionType =
  | 'ProjektOeffnen'
  | 'NutzerAnlegen'
  | 'NutzerBearbeiten'
  | 'AuftragBearbeiten'
  | 'RechnungBearbeiten'
  | 'ProjektLoeschen'
  | 'ProjektAnlegen';

export interface Guardable {
  requiredPermissions?: PermissionType[];
  projectId?: string | null;
}

interface AuthorizationContextValue extends AuthorizationContextState {
  fetchPermissions: () => Promise<void>;
  clearPermissions: () => void;
  checkPermission: (
    permissionType: PermissionType[],
    id?: string | null
  ) => boolean;
}

const AuthorizationContext = createContext<AuthorizationContextValue>({
  permissions: [],
  roles: [],
  fetchPermissions: () => Promise.resolve(),
  clearPermissions: () => {},
  checkPermission: () => {
    throw Error('no AuthorizationProvider installed');
  },
  loading: true,
});

const GET_PERMISSIONS = gql`
  fragment Role on CURollen {
    bez
    kw
    projekte
  }

  query GetPermissions {
    benutzer {
      akutellerNutzer {
        rechte {
          id
          instanzen
        }
        rollen {
          ...Role
        }
      }
    }
  }
`;

export const AuthorizationProvider: FC<{}> = ({ children }) => {
  const apollo = useApolloClient();

  const [roles, setRoles] = useState<Role[]>([]);
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchPermissions = async (): Promise<void> => {
    try {
      setLoading(true);
      const { data } = await apollo.query<GetPermissions>({
        query: GET_PERMISSIONS,
      });

      const permissions = (data.benutzer?.akutellerNutzer?.rechte || []).map(
        ({ id, instanzen }) => ({
          id,
          instances: instanzen.split(','),
          allowAll: instanzen === '*',
        })
      );
      const roles = data.benutzer?.akutellerNutzer?.rollen || [];

      console.log('setting permissions');

      setPermissions(permissions);
      setRoles(roles);
    } catch (ex) {
      console.log('fetching permissions broken', ex);
      setPermissions([]);
      setRoles([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermission = (
    requiredPermissions: PermissionType[],
    projectId?: string | null
  ) => {
    try {
      requiredPermissions.forEach((requiredPermissionType) => {
        const userPermission = permissions.find(
          ({ id }) => requiredPermissionType === id
        );

        if (!userPermission) {
          throw Error;
        }

        if (
          projectId &&
          !(
            userPermission.instances.find(
              (instance) => instance === projectId
            ) || userPermission.allowAll
          )
        ) {
          throw Error;
        }
      });
    } catch {
      return false;
    }

    return true;
  };

  const clearPermissions = (): void => {
    setPermissions([]);
    setRoles([]);
  };

  return (
    <AuthorizationContext.Provider
      value={{
        roles,
        permissions,
        fetchPermissions,
        clearPermissions,
        checkPermission,
        loading,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

export default AuthorizationContext;
