import { isValid } from 'date-fns';
import { FieldHookConfig, useField } from 'formik';
import FormField from '../form/FormField';
import { FormGridProps } from '../form/FormGridProps';
import DateInput from './DateInput';

interface FormProps {
  label?: string;
}

const FormDateInput = ({
  xs,
  sm,
  md,
  lg,
  xl,
  ...props
}: FormProps & FormGridProps & FieldHookConfig<string>) => {
  const [field, meta, form] = useField<string | null>(props.name);
  const handleChange = (input: string, pickerDate?: Date | null) => {
    if (pickerDate && isValid(pickerDate)) {
      form.setTouched(true, false);
      form.setValue(input);
    } else if (input) {
      form.setValue(input);
      form.setTouched(false, false);
    } else {
      form.setTouched(true, false);
      form.setValue(null);
    }
  };
  const handleBlur = (input: string | undefined) => {
    form.setTouched(true);
    form.setValue(input || null);
  };

  return (
    <FormField xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
      <DateInput
        label={props.label}
        value={field.value}
        onChange={handleChange}
        onBlur={handleBlur}
        error={meta.touched && meta.error ? meta.error : undefined}
        limitRerender
      />
    </FormField>
  );
};

export default FormDateInput;
