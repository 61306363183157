import { Box, Button } from '@mui/material';
import { useContext } from 'react';
import { FilterBarContext } from './FilterBar';
import ClearIcon from '@mui/icons-material/Clear';
interface FilterClearButtonProps {
  buttonText?: string;
  showIcon?: boolean;
}

const FilterClearButton = ({
  buttonText = 'Filter zurücksetzen',
  showIcon = false,
}: FilterClearButtonProps) => {
  const filterContext = useContext(FilterBarContext);

  return (
    <Box height="100%">
      <Button
        onClick={filterContext.clear}
        disabled={
          Object.values(filterContext.appliedFilterState).length === 0 &&
          !filterContext.searchValue
        }
      >
        {buttonText}
        {showIcon && <ClearIcon fontSize="small" />}
      </Button>
    </Box>
  );
};

export default FilterClearButton;
