import { Container, Breakpoint } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { BreadcrumbItemProps } from '../navigation/BreadcrumbItem';
import PageHeader from './PageHeader';

interface PageProps {
  title: string;
  name: string;
  buttons?: () => JSX.Element | null | undefined;
  breadcrumbItems?: BreadcrumbItemProps[];
  maxWidth?: false | Breakpoint | undefined;
}

const Page: FC<PageProps> = ({
  name,
  title,
  children,
  buttons,
  breadcrumbItems,
  maxWidth = 'xl',
}) => (
  <>
    <Helmet>
      <title>{title}</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 2,
      }}
    >
      <Container maxWidth={maxWidth}>
        <PageHeader name={name} breadcrumbItems={breadcrumbItems}>
          {buttons && buttons()}
        </PageHeader>
        <Box sx={{ mt: 3 }}>{children}</Box>
      </Container>
    </Box>
  </>
);

export default Page;
