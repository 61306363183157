import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from '@mui/material';
import { FC } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

interface SidebarMenuEntry {
  title: string;
  path: string;
  icon: JSX.Element;
}

interface SidebarMenuProps {
  entries: SidebarMenuEntry[];
  iconOnly?: boolean;
}

const SidebarMenu: FC<SidebarMenuProps> = ({ entries, iconOnly = false }) => {
  const theme = useTheme();

  return (
    <List sx={{ width: '100%', padding: 0 }}>
      {entries.map(({ title, path, icon }, index) => (
        <div key={'sidebar-' + title + '-' + index}>
          {iconOnly ? (
            <Tooltip title={title} disableInteractive arrow placement="right">
              <ListItemButton
                component={RouterLink}
                to={path}
                key={title}
                activeClassName="Mui-selected"
              >
                <ListItemIcon
                  sx={{
                    marginLeft: { lg: 1 },
                    transition: theme.transitions.create('margin-left', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                  }}
                >
                  {icon}
                </ListItemIcon>
              </ListItemButton>
            </Tooltip>
          ) : (
            <ListItemButton
              component={RouterLink}
              to={path}
              key={title}
              activeClassName="Mui-selected"
            >
              <ListItemIcon
                sx={{
                  marginLeft: { sm: 1 },
                }}
              >
                {icon}
              </ListItemIcon>
              <ListItemText primary={title} sx={{ margin: 0 }} />
            </ListItemButton>
          )}
        </div>
      ))}
    </List>
  );
};

export default SidebarMenu;
