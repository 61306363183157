import { Box } from '@mui/material';
import { useCallback, useContext } from 'react';
import { FilterBarContext, FilterInputProps } from './FilterBar';
import RemoveIcon from '@mui/icons-material/Remove';
import DateInput from '../../inputs/DateInput';
import { parseISO } from 'date-fns/esm';

const DateRangeFilter = ({ ...props }: FilterInputProps) => {
  const filterContext = useContext(FilterBarContext);
  const state = filterContext.filterState[props.id]?.state;

  const setFilter = useCallback(
    (values: { min?: string | null; max?: string | null }) => {
      if (values.min || values.max) {
        filterContext.setFilter({
          filterFunction: 'BETWEEN_DATES',
          state: { ...values },
          filterText:
            props.heading +
            ': ' +
            (values.max ? '' : 'ab dem ') +
            (values.min ? parseISO(values.min).toLocaleDateString() : '') +
            (values.min && values.max
              ? '-'
              : !values.min && values.max
              ? 'bis zum '
              : '') +
            (values.max ? parseISO(values.max).toLocaleDateString() : ''),
          propertyName: props.propertyName,
          id: props.id,
        });
      } else {
        filterContext.deleteFilter(props.id);
      }
    },
    [filterContext, props.id, props.heading, props.propertyName]
  );

  return (
    <>
      <Box sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
        <Box flexGrow={1}>
          <DateInput
            autoFocus={props.autoFocus && !state?.min}
            label={'Min'}
            value={state?.min || null}
            onChange={(value) => {
              setFilter({ min: value, max: state?.max });
            }}
            onBlur={(value) => {
              setFilter({ min: value, max: state?.max });
            }}
          />
        </Box>
        <RemoveIcon />
        <Box flexGrow={1}>
          <DateInput
            autoFocus={props.autoFocus && !!state?.min && !state?.max}
            label={'Max'}
            value={state?.max || null}
            onChange={(value) => {
              setFilter({ min: state?.min, max: value });
            }}
            onBlur={(value) => {
              console.log('blur', value);
              setFilter({ min: state?.min, max: value });
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default DateRangeFilter;
