import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Box, Divider, IconButton } from '@mui/material';
import { Theme, CSSObject, styled } from '@mui/material/styles';
import { FC } from 'react';
import useProject from '../../hooks/useProject';
import SidebarMenu from './SidebarMenu';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import MuiDrawer from '@mui/material/Drawer';
import useSessionState from '../../hooks/useSessionState';

const drawerWidth = 210;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('lg')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
const getMainMenu = (isOpen: boolean) => {
  const fontSize = isOpen ? 'medium' : 'medium';
  return [
    {
      title: 'Projekte',
      path: '/projects',
      icon: <BarChartIcon fontSize={fontSize} />,
    },
    {
      title: 'Stammdaten',
      path: '/stammdaten',
      icon: <PieChartIcon fontSize={fontSize} />,
    },
    {
      title: 'Benutzer',
      path: `/benutzer`,
      icon: <PieChartIcon fontSize={fontSize} />,
    },
    {
      title: 'Berichte',
      path: `/berichte`,
      icon: <PieChartIcon fontSize={fontSize} />,
    },
  ];
};

const getProjectMenu = (projectId: string, isOpen: boolean) => {
  const fontSize = isOpen ? 'medium' : 'medium';
  return [
    {
      title: 'Dashboard',
      path: `/projects/${projectId}/dashboard`,
      icon: <BarChartIcon fontSize={fontSize} />,
    },
    {
      title: 'Kostenplanung',
      path: `/projects/${projectId}/costs`,
      icon: <PieChartIcon fontSize={fontSize} />,
    },
    {
      title: 'Aufträge',
      icon: <FolderOpenIcon fontSize={fontSize} />,
      path: `/projects/${projectId}/orders`,
    },
    {
      title: 'Rechnungen',
      icon: <ReceiptIcon fontSize={fontSize} />,
      path: `/projects/${projectId}/invoices`,
    },

    {
      title: 'Auswertung',
      path: `/projects/${projectId}/analysis`,
      icon: <ShoppingBagIcon fontSize={fontSize} />,
    },
    {
      title: 'Berichte',
      path: `/projects/${projectId}/reports`,
      icon: <AssessmentIcon fontSize={fontSize} />,
    },
    {
      title: 'Formulartest',
      path: `/projects/${projectId}/formulartest`,
      icon: <AssessmentIcon fontSize={fontSize} />,
    },
  ];
};
const Sidebar: FC = (props) => {
  const [open, setOpen] = useSessionState(false, 'sidebarState');

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const project = useProject();
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: open ? 'end' : 'center',
        }}
      >
        <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'stretch',
          marginTop: 1,
        }}
      >
        <SidebarMenu
          entries={
            project
              ? getProjectMenu(project?.id as string, open)
              : getMainMenu(open)
          }
          iconOnly={!open}
        />
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px) !important',
          top: '64px !Important',
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  );
};

export default Sidebar;
